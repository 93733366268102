import React, { useState, useEffect, useContext } from "react";
import Select from "react-select";
import DataContext from "../../../utils/Context/DataContext";
import { AppConfig } from "../../../config";
import SwipeableBottomSheet from "@geomatico/react-swipeable-bottom-sheet";

import { Dialog, makeStyles } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import bootstrap from "../../../assets/libraries/bootstrap/js/bootstrap";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("xs")]: {
      alignItems: "flex-end", // push the dialog to bottom
    },
  },

  paper: {
    // make the content full width
    [theme.breakpoints.down("xs")]: {
      margin: 0,
      maxWidth: "100%",
      width: "100%",
      overflow: "scroll",
      borderTopLeftRadius: "20px",
      borderTopRightRadius: "20px",
    },
    overflow: "scroll",
    width: "50%",
  },
}));

export default function LiveTrackingModalUI(props: any) {
  const context: any = useContext(DataContext);
  const classes = useStyles();
  var importedScript = document.createElement("script");
  const [tracking_status, SetTrackingStatus] = useState("");
  let map: any;
  let myModal: any;
  var driver_marker: any;
  const map_styler = [
    {
      featureType: "administrative",
      elementType: "geometry",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "poi",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "transit",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
  ];
  let marker_img_url = "https://cdn3.1superapp.com/superapp/deliveryboy.png";
  const navigate = useNavigate();

  // useEffect(() => {
  //   let myModal1: any = document.getElementById("MapTracker");
  //   myModal1.addEventListener("hidden.bs.modal", function (event) {
  //     console.log("event :", event);
  //     importedScript.remove();
  //     myModal = "";
  //     props.close();
  //   });
  // }, []);

  // useEffect(() => {
  //   if (props.open) {
  //     myModal = new bootstrap.Modal(document.getElementById("MapTracker"), {
  //       backdrop: false,
  //     });
  //     renderMap();
  //     myModal.show();
  //   }
  // }, [props.open]);

  const renderMap = () => {
    window.initMap = initMap;
  };

  function initMap(): void {
    const directionsService = new google.maps.DirectionsService();
    const directionsRenderer = new google.maps.DirectionsRenderer();
    map = new google.maps.Map(
      document.getElementById("map-live-track") as HTMLElement,
      {
        zoom: 12,
        center: {
          lat: 13.067439,
          lng: 80.237617,
          // lat: parseFloat(order_details_value.lat),
          // lng: parseFloat(order_details_value.lng),
        },
        mapTypeControl: false,
        fullscreenControl: false,
        zoomControl: false,
        streetViewControl: false,
        mapTypeId: "roadmap",
        gestureHandling: "greedy",
        styles: map_styler,
      }
    );

    directionsRenderer.setMap(map);

    driver_marker = new google.maps.Marker({
      position: null,
      map,
      icon: {
        url: marker_img_url,
        scaledSize: new google.maps.Size(23, 35),
      },
      anchorPoint: new google.maps.Point(0, -29),
    });
    console.log("order_details_value in initmap :", order_details_value);
    // resturant marker
    new google.maps.Marker({
      position: {
        lat: 13.067439,
        lng: 80.237617,
        // lat: parseFloat(order_details_value.lat),
        // lng: parseFloat(order_details_value.lng),
      },
      map: map,
      icon: {
        url: "https://cdn3.1superapp.com/superapp/markerpinup.png",
        scaledSize: new google.maps.Size(23, 35),
      },
      anchorPoint: new google.maps.Point(0, -29),
    });
    // customer marker
    new google.maps.Marker({
      position: {
        lat: 13.067439,
        lng: 80.237617,
        // lat: parseFloat(order_details_value.delivery_address.lat),
        // lng: parseFloat(order_details_value.delivery_address.lng),
      },
      map: map,
      icon: {
        url: "https://cdn3.1superapp.com/superapp/markerpindown.png",
        scaledSize: new google.maps.Size(23, 35),
      },
      anchorPoint: new google.maps.Point(0, -29),
    });
  }

  useEffect(() => {
    if (tracking_status === "") {
      setTimeout(() => {
        SetTrackingStatus("accpeted");
      }, 3000);
    } else if (tracking_status === "accpeted") {
      setTimeout(() => {
        SetTrackingStatus("preparing");
      }, 5000);
    } else if (tracking_status === "preparing") {
      setTimeout(() => {
        SetTrackingStatus("delivery_partner_assigned");
      }, 5000);
    } else if (tracking_status === "delivery_partner_assigned") {
      setTimeout(() => {
        SetTrackingStatus("order_pickuped");
      }, 3000);
    } else if (tracking_status === "order_pickuped") {
      setTimeout(() => {
        SetTrackingStatus("order_delivered");
      }, 3000);
    } else if (tracking_status === "order_delivered") {
      setTimeout(() => {
        SetTrackingStatus("completed");
      }, 3000);
    }
  }, [tracking_status]);

  return (
    <div className="page-main margin-footer">
      {/* <div
    className="modal fade"
    id="MapTracker"
    tabIndex={-1}
    aria-labelledby="ModalLabel"
    aria-hidden="true"
  > */}
      <div
        className="modal-dialog  modal-fullscreen custom-container"
        data-backdrop="false"
      >
        <div className="modal-content">
          {/* header */}
          <div className="modal-header address-map-header shadow px-2">
            {/* title */}
            <div className="px-2 cursor" data-bs-dismiss="modal">
              <img
                src={
                  AppConfig.CDN_Media_url +
                  "30549c56761b976b23418471a0f0037a.svg"
                }
                className="w-14px"
              />
            </div>
            <div className="ps-1 pe-0 w-100">
              <div className="loc-head">Order ID: #35620</div>
              <div className="fs-9px pop-font text-primary">View Details</div>
            </div>
            <div className="ms-auto">
              <img
                src={
                  AppConfig.CDN_Media_url +
                  "456a4de3cc461bb54526238669d7140c.svg"
                }
                className="w-16px"
              />
            </div>
          </div>

          {/* modal body */}
          <div className="modal-body p-0">
            {/* <div id="map-live-track"></div> */}

            <div className="map-over center sm-block md-none bg-fff">
              <SwipeableBottomSheet overflowHeight={350} overlay={false}>
                <div className="p-2 bg-fff sticky-top custom-swipe-shadow">
                  <div className="center d-md-none d-sm-block pb-3">
                    <hr className="hr-drawer" />
                  </div>

                  <div className="loc-head">
                    Delivery to HOME in 15 minutes by 02:15 PM
                  </div>
                  <div className="address-sub-text fs-11 mt-1">
                    13, Rangan 2nd Street, Karungalpalayam, Pavalatham Palayam,
                    Erode, Tamil Nadu, India, 638003
                  </div>

                  <div className="py-3 ">
                    <div className="progress">
                      <div
                        className={
                          tracking_status == "accpeted"
                            ? "progress-bar w-10per bg-prime1"
                            : tracking_status == "preparing"
                            ? "progress-bar w-20per bg-prime1"
                            : tracking_status == "delivery_partner_assigned"
                            ? "progress-bar w-30per bg-prime1"
                            : tracking_status == "order_pickuped"
                            ? "progress-bar w-50per bg-prime1"
                            : tracking_status == "order_delivered"
                            ? "progress-bar w-90per bg-prime1"
                            : tracking_status == "completed"
                            ? "progress-bar w-100 bg-prime1"
                            : "progress-bar "
                        }
                        // className={}
                      ></div>
                    </div>
                  </div>

                  <div className="bg-light-blue p-2 radius-10 mb-2">
                    <div className="d-flex align-items-center pb-2 border-bottom">
                      <div className="w-30px bg-fff h-30px radius-6 center">
                        <img
                          src={
                            AppConfig.CDN_Image_url +
                            "b768514a88b9a901a98ea3bb85c71049.png"
                          }
                          className="w-10 "
                        />
                      </div>
                      <div className="ps-2">
                        <div className="loc-head fs-12">Anu</div>
                      </div>
                      <div className="ms-auto end">
                        <div className="os-icon shadow center">
                          <img
                            src={
                              AppConfig.CDN_Media_url +
                              "a78b858bd49bf56c9fa495b641269677.svg"
                            }
                            className="w-16px"
                          />
                        </div>
                        <div className="ms-3 os-icon shadow center">
                          <img
                            src={
                              AppConfig.CDN_Media_url +
                              "ce8b7d884ffdb51228e7577cc36f38c0.svg"
                            }
                            className="w-16px"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="d-flex align-items-center py-2 border-bottom">
                      <div className="w-30px bg-fff">
                        <img
                          src={
                            AppConfig.CDN_Image_url +
                            "62eeaf50fa0a9a609efe846632b0fc47.jpg"
                          }
                          className="track-profile-img"
                        />
                      </div>
                      <div className="ps-2 d-flex">
                        <span className="loc-head fs-12">
                          Sri Sai Food
                          <span className="ps-1 fs-11px pop-regular text-gray fw-400">
                            {tracking_status == "accpeted"
                              ? "is accpeted your order"
                              : tracking_status == "preparing"
                              ? "is Preparing your order"
                              : ""}
                          </span>
                        </span>
                      </div>
                      {tracking_status == "accpeted" ||
                      tracking_status == "preparing" ? (
                        <div className="ms-auto end">
                          <div className="os-icon shadow center">
                            <img
                              src={
                                AppConfig.CDN_Media_url +
                                "a78b858bd49bf56c9fa495b641269677.svg"
                              }
                              className="w-16px"
                            />
                          </div>
                          <div className="ms-3 os-icon shadow center">
                            <img
                              src={
                                AppConfig.CDN_Media_url +
                                "ce8b7d884ffdb51228e7577cc36f38c0.svg"
                              }
                              className="w-16px"
                            />
                          </div>
                        </div>
                      ) : null}
                    </div>

                    <div className="d-flex align-items-center py-2">
                      {tracking_status == "delivery_partner_assigned" ||
                      tracking_status == "order_pickuped" ||
                      tracking_status == "order_delivered" ||
                      tracking_status == "completed" ? (
                        <div className="w-30px bg-fff">
                          <img
                            src={
                              AppConfig.CDN_Image_url +
                              "d112a6cd7325a2ce2b456d5f22baea4a.jpg"
                            }
                            className="track-profile-img "
                          />
                        </div>
                      ) : (
                        <div className="w-30px bg-fff h-30px radius-6 text-center">
                          <img
                            src={
                              AppConfig.CDN_Image_url +
                              "b768514a88b9a901a98ea3bb85c71049.png"
                            }
                            className="w-10"
                          />
                        </div>
                      )}
                      <div className="ps-2 d-flex">
                        <span className="loc-head fs-12">
                          {tracking_status == "delivery_partner_assigned" ||
                          tracking_status == "order_pickuped" ||
                          tracking_status == "order_delivered" ||
                          tracking_status == "completed"
                            ? "Sundar"
                            : null}
                          <span className="ps-1 fs-11px pop-regular text-gray fw-400">
                            {tracking_status == "delivery_partner_assigned"
                              ? "is picked your order"
                              : tracking_status == "order_pickuped"
                              ? "is picked your order"
                              : tracking_status == "order_delivered"
                              ? "is delivered your order"
                              : tracking_status == "completed"
                              ? "is complete your order"
                              : "We are look our delivery partner to pick your order"}
                          </span>
                        </span>
                      </div>
                      {tracking_status == "delivery_partner_assigned" ||
                      tracking_status == "order_pickuped" ||
                      tracking_status == "order_delivered" ? (
                        <div className="ms-auto end">
                          <div className="os-icon shadow center">
                            <img
                              src={
                                AppConfig.CDN_Media_url +
                                "a78b858bd49bf56c9fa495b641269677.svg"
                              }
                              className="w-16px"
                            />
                          </div>
                          <div className="ms-3 os-icon shadow center">
                            <img
                              src={
                                AppConfig.CDN_Media_url +
                                "ce8b7d884ffdb51228e7577cc36f38c0.svg"
                              }
                              className="w-16px"
                            />
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </SwipeableBottomSheet>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
