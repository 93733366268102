import React, {
  Component,
  useEffect,
  useContext,
  useState,
  useRef,
} from "react";
import {
  Link,
  useParams,
  useSearchParams,
  useOutletContext,
  useNavigate,
} from "react-router-dom";
import { AppConfig } from "../../../config";
import DataContext from "../../../utils/Context/DataContext";
import AliceCarousel from "react-alice-carousel";
const foodresponsive = {
  0: { items: 1 },
  300: { items: 1 },
  350: { items: 1 },
  548: { items: 1 },
  // 720: { items: 4 },
  // 912: { items: 6 },
  // 1024: { items: 6 },
};

const foodList =[
  {
    name: "Chicken Briyani",
    description:
      "Biryani is a flavorful, layered rice dish that's popular in South Asia and is often made with meat, but can also be made vegetarian",
    type: "Veg",
    mrp: 80.0,
    price: 70.0,
  },
  {
    name: "Chicken Briyani",
    description:
      "Biryani is a flavorful, layered rice dish that's popular in South Asia and is often made with meat, but can also be made vegetarian",
    type: "Veg",
    mrp: 80.0,
    price: 70.0,
  },
];

const handleDragStart = (e) => e.preventDefault();

export default function SearchResult(props: any) {
  const navigate = useNavigate();
  const context: any = useContext(DataContext);
  const [active_resta, SetActiveResta] = useState(true);
  const [active_dish, SetActiveDish] = useState(false);
  const [fav, SetFav] = useState(false);
  const [vegetarian, SetVegetarian] = useState(true);

  const get_diet_icon = () => {
    return (
      <>
        {/* {get_data?.specifications?.map((ele: any) =>
          ele.id == AppConfig.diet_type ? ( */}
        <>
          {vegetarian ? (
            //   {ele.value[0].id == AppConfig.vegetarian ? (
            <img
              src={
                AppConfig.CDN_Image_url + "e301ce3af353aab421a2f76c898a439d.png"
              }
              className="type-img me-2"
            />
          ) : null}
          {/* {ele.value[0].id == AppConfig.non_vegetarian ? (
                <img
                  src={
                    AppConfig.CDN_Image_url +
                    "5c7a11ae863a9b5cfa0e1844c7a186b9.png"
                  }
                  className="type-img me-2"
                />
              ) : null}
              {ele.value[0].id == AppConfig.eggetarian ? (
                <img
                  src={
                    AppConfig.CDN_Image_url +
                    "fea28c272b8bbfb74f54b073bea5cfaa.png"
                  }
                  className="type-img me-2"
                />
              ) : null}
              {ele.value[0].id == AppConfig.vegan ? (
                <img
                  src={
                    AppConfig.CDN_Image_url +
                    "1e2d32e8b1e3f2fd5eb65c3b8957674e.png"
                  }
                  className="type-img me-2"
                />
              ) : null} */}
        </>
        {/* ) : null
        )} */}
      </>
    );
  };

  return (
    <div className="custom-container p-3">
      <div className="address-search-bar d-flex align-items-center mb-3">
        <img
          src={AppConfig.CDN_Media_url + "4075afa7aafecff54a5220213ad396f0.svg"}
          className="w-20px"
        />
        <div className="ps-2 ">
          <input
            type="text"
            className="let_space_03 custom-input-text"
            placeholder="Search for foods or restaurant"
          />
        </div>
        <div className="ms-auto cursor ps-2">
          <img
            src={
              AppConfig.CDN_Media_url + "d6da0c9c3517352622470b635f8a8d38.svg"
            }
            className="w-20px"
          />
        </div>
      </div>

      <div className="mt-3 d-flex align-items-center border-bottom">
        <div className="px-1">
          <div
            className={
              active_resta
                ? "loc-head active-tab-black p-1"
                : "sub-head-text p-1"
            }
            onClick={() => {
              SetActiveResta(true);
              SetActiveDish(false);
            }}
          >
            Restaurant
          </div>
        </div>
        <div className="px-2">
          <div
            className={
              active_dish
                ? "loc-head active-tab-black p-1"
                : "sub-head-text p-1"
            }
            onClick={() => {
              SetActiveResta(false);
              SetActiveDish(true);
            }}
          >
            Dish
          </div>
        </div>
      </div>

      {active_resta ? (
        <div className="py-3">
          <div className="card radius-10 shadow py-3 px-2">
            <div className="d-flex pb-2 border-bottom mb-2">
              <img
                src={
                  AppConfig.CDN_Image_url +
                  "d11bb99ad9714b9425eb9dcf938eb240.jpg"
                }
                className="search-restaurant-img"
              />
              <div className="ps-2">
                <div className="loc-head ">Briyani Stall</div>
                <div className="d-flex align-items-center pt-1">
                  <img
                    src={
                      AppConfig.CDN_Media_url +
                      "7267ee67b40d8913d88ebd3420682df3.svg"
                    }
                    className="img-fluid pe-1"
                    width={14}
                  />
                  <div className="address-sub-text text-black-color let_space_03">
                    30-40 mins
                  </div>
                </div>
                <div className="address-sub-text let_space_03">
                  Erode, Karungalpalayam
                </div>
              </div>
            </div>
            <div className="d-flex pb-2 ">
              <img
                src={
                  AppConfig.CDN_Image_url +
                  "d11bb99ad9714b9425eb9dcf938eb240.jpg"
                }
                className="search-restaurant-img"
              />
              <div className="ps-2">
                <div className="loc-head ">Briyani Stall</div>
                <div className="d-flex align-items-center pt-1">
                  <img
                    src={
                      AppConfig.CDN_Media_url +
                      "7267ee67b40d8913d88ebd3420682df3.svg"
                    }
                    className="img-fluid pe-1"
                    width={14}
                  />
                  <div className="address-sub-text text-black-color let_space_03">
                    30-40 mins
                  </div>
                </div>
                <div className="address-sub-text let_space_03">
                  Erode, Karungalpalayam
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="py-3">
          <div className="card radius-10 shadow py-3 px-2">
            <div className="d-flex pb-3">
              <img
                src={
                  AppConfig.CDN_Image_url +
                  "d11bb99ad9714b9425eb9dcf938eb240.jpg"
                }
                className="search-restaurant-img"
              />
              <div className="ps-2">
                <div className="loc-head ">Briyani Stall</div>
                <div className="d-flex align-items-center pt-1">
                  <img
                    src={
                      AppConfig.CDN_Media_url +
                      "7267ee67b40d8913d88ebd3420682df3.svg"
                    }
                    className="img-fluid pe-1"
                    width={14}
                  />
                  <div className="address-sub-text text-black-color let_space_03">
                    30-40 mins
                  </div>
                </div>
                <div className="address-sub-text let_space_03">
                  Erode, Karungalpalayam
                </div>
              </div>
              <div className="ms-auto">
                <img
                  src={
                    AppConfig.CDN_Media_url +
                    "47cb7b701cb1874df18c97575cb28756.svg"
                  }
                  className="img-fluid me-1"
                  width={22}
                />
              </div>
            </div>

            <AliceCarousel
              mouseTracking
              // touchMoveDefaultEvents={false}
              paddingRight={30}
              disableDotsControls
              responsive={foodresponsive}
              disableButtonsControls
              controlsStrategy="alternate"
            >
              {foodList.map((f_ele: any, f_index: any) => (
                <div onDragStart={handleDragStart} key={f_index} className="me-2">
                  <div className="border radius-10 p-2 bg-fff row ">
                    <div className="col-8 p-0">
                      <div className="d-flex align-items-center">
                        <img
                          src={
                            AppConfig.CDN_Image_url +
                            "e301ce3af353aab421a2f76c898a439d.png"
                          }
                          className="type-img me-2"
                        />
                        <div className="cursor ms-1">
                          {fav ? (
                            <img
                              src={
                                AppConfig.CDN_Media_url +
                                "eff3dd986a87c9e8224c9547708adcd4.svg"
                              }
                              className="w-20"
                            />
                          ) : (
                            <img
                              src={
                                AppConfig.CDN_Media_url +
                                "717d8ac4005e4eda5f72bd6b5485d4be.svg"
                              }
                              className="w-20"
                            />
                          )}
                        </div>
                      </div>
                      <div className="loc-head pt-1 mb-1 ">{f_ele.name}</div>
                      <div
                        className="marg celias-font h-33px address-sub-text"
                        // {g_props.data.description != null ? "marg celias-font h-33px" : "h-33px" }
                      >
                        {f_ele.description}
                      </div>

                      <div className="address-text text-black-color">₹{f_ele.price}</div>
                      <div className="address-sub-text strike">
                        ₹{f_ele.mrp}
                      </div>
                    </div>
                    <div className="col-4 p-0">
                      <div className="near-img">
                        <img
                          src={
                            AppConfig.CDN_Image_url +
                            "d11bb99ad9714b9425eb9dcf938eb240.jpg"
                          }
                          className="near-img "
                        />
                      </div>
                      <div className="bottom-offer">
                        <div className="offer p-0 d-flex align-items-center">
                          <div className="cursor p-2 text-green pop-font w-100">
                            Add
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </AliceCarousel>
          </div>
        </div>
      )}
    </div>
  );
}
