export const AppConfig = {
  base_url: {
    api: 'https://'+ window.location.hostname +'/app', // for live
    superapp: "https://",
  },
  user_agent: {
    superapp: {
      android: "",
      ios: "",
    },
  },
  version_number : "3.2.0",
  CDN_Media_url : "https://cdn3.1superapp.com/media/",
  CDN_Image_url : "https://cdn2.1superapp.com/images/",
  diet_type: 115,
  vegetarian: 116,
  non_vegetarian: 117,
  eggetarian: 118,
  vegan: 119,
  google_map_api:"AIzaSyCpb5cwbblyXgVG-ZsUdA4WdUSFWhBxgWE",//2nd
  
  // environment : "Development",
  environment : "Production",
  };