import { api } from "../Api";

// const geocoder = new window.google.maps.Geocoder();

const imageSrc = (base_url: any, img_url: any, data = {}) => {
  let pass_string = base_url + img_url;
  if (Object.keys(data).length > 0) {
    Object.entries(data).forEach(([key, value], index) => {
      if (index == 0) {
        let join_data;
        join_data = "?" + key + "=" + value;
        pass_string = pass_string + join_data;
      } else {
        let join_data = "&" + key + "=" + value;
        pass_string = pass_string + join_data;
      }
    });
  }
  return pass_string;
};

const randomStr: any = (length: number) => {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
};

const base64toBlob = (b64Data: any, contentType = "", sliceSize = 512) => {
  const byteCharacters = atob(
    b64Data.replace(/^data:image\/(png|jpeg|jpg);base64,/, "")
  );
  // const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

const ConvertRupee = (get_data: any) => {
  let rupee = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  });
  return rupee.format(get_data);
};

/**
 * Performs the even-odd-rule Algorithm (a raycasting algorithm) to find out whether a point is in a given polygon.
 * This runs in O(n) where n is the number of edges of the polygon.
 *
 * @param {Array} polygon an array representation of the polygon where polygon[i][0] is the x Value of the i-th point and polygon[i][1] is the y Value.
 * @param {Array} point   an array representation of the point where point[0] is its x Value and point[1] is its y Value
 * @return {boolean} whether the point is in the polygon (not on the edge, just turn < into <= and > into >= for that)
 */

const pointInErode = function (point: any) {
  //A point is in a polygon if a line from the point to infinity crosses the polygon an odd number of times
  let odd = false;
  let polygon = [
    [11.402455, 77.689501],
    [11.364031, 77.73972],
    [11.302567, 77.778145],
    [11.29313, 77.696897],
    [11.310746, 77.663826],
    [11.355983, 77.662601],
    [11.387604, 77.6675],
    [11.402455, 77.689501],
  ];
  //For each edge (In this case for each point of the polygon and the previous one)
  for (let i = 0, j = polygon.length - 1; i < polygon.length; i++) {
    //If a line from the point into infinity crosses this edge
    if (
      polygon[i][1] > point[1] !== polygon[j][1] > point[1] && // One point needs to be above, one below our y coordinate
      // ...and the edge doesn't cross our Y corrdinate before our x coordinate (but between our x coordinate and infinity)
      point[0] <
        ((polygon[j][0] - polygon[i][0]) * (point[1] - polygon[i][1])) /
          (polygon[j][1] - polygon[i][1]) +
          polygon[i][0]
    ) {
      // Invert odd
      odd = !odd;
    }
    j = i;
  }
  //If the number of crossings was odd, the point is in the polygon
  return odd;
};

async function SaveLocation(position_data: any) {
  console.log("SaveLocation position_data :", position_data);
  let pass_data: any = {
    post: {
      lat: position_data.coords.latitude,
      lng: position_data.coords.latitude,
      location: position_data,
    },
  };
  console.log("res save_location pass_data :", pass_data);
  let response = await api("/location", pass_data);
  console.log("res location :", response);
  if (response.status_code == 200) {
  }
}

// function GetAddressByLocation(get_data: any) {
//   let return_data = "";
//   geocoder.geocode({ location: get_data.location }, (results : any, status) => {
//     if (status === "OK" && results[0]) {
//       // setAddress(results[0].formatted_address);
//       return_data = results[0].formatted_address;
//     } else {
//       // setAddress("No address found");
//       return_data = "";
//     }
//   });
//   return return_data;
// }

export const AppHelper = {
  imageSrc,
  randomStr,
  base64toBlob,
  ConvertRupee,
  SaveLocation,
  pointInErode,
  // GetAddressByLocation,
};
