import React from "react";
import "./image.css";

const ImageCom = (props) => {
  const [isLoaded, setIsLoaded] = React.useState(false);
  return (
    <React.Fragment>
      {/* {isLoaded ? ( */}
      <div
        className={props.thumb_class_name ? props.thumb_class_name + " lazy-load-thumb" : props.class_name + " lazy-load-thumb"}
        // src={props.thumb}
        // src={"../../assets/icon/cooking.jpg"}
        // style={{ visibility: isLoaded ? 'hidden' : 'visible' }}
        // style={{ display: isLoaded ? 'none' : 'block' }}
        style={
          isLoaded
            ? { visibility: "hidden", display: "none" }
            : { visibility: "visible", display: "block" }
        }
        // style={!isLoaded ? (
        //   Object.keys(props?.style_value)?.length > 0 ? (
        //     { visibility: "hidden", display: "none" } 
        //   ) : (
        //     { visibility: "hidden", display: "none" }
        //   )
        // ) : 
        //   { visibility: "visible", display: "block" } +  props.style_value
        // }
      />
      {/* <div
        className={props.class_name + " thumb"}
        style={
          isLoaded
            ? { visibility: "hidden", display: "none" }
            : { visibility: "visible", display: "block" }
        }
      ></div> */}
      {/* ) : null} */}

      <img
        onLoad={() => {
          setIsLoaded(true);
        }}
        className={props.class_name + " full"}
        // style={{ opacity: isLoaded ? 1 : 0 }}
        style={
          isLoaded
            ? { visibility: "visible", display: "block", opacity: 1 }
            : { visibility: "hidden", display: "none", opacity: 0 }
        }
        src={props.src}
      />
    </React.Fragment>
  );
};

export default ImageCom;
