import React, { useState, useEffect, useContext } from "react";
import Select from "react-select";
import DataContext from "../../../utils/Context/DataContext";
import { AppConfig } from "../../../config";

import { Dialog, Drawer, makeStyles } from "@material-ui/core";
import { Modal } from "../../../assets/libraries/bootstrap/js/bootstrap";
import CouponModal from "./CouponModal";
import { BorderTopRounded } from "@material-ui/icons";
import Skeleton from "react-loading-skeleton";
import SwipeableBottomSheet from "@geomatico/react-swipeable-bottom-sheet";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("xs")]: {
      alignItems: "flex-end", // push the dialog to bottom
    },
  },
  paper: {
    // make the content full width
    [theme.breakpoints.down("xs")]: {
      margin: 0,
      maxWidth: "100%",
      width: "100%",
      overflow: "scroll",
      paddingBottom: "45px",
      maxHeight: "100% !important",
    },
    [theme.breakpoints.up("md")]: {
      overflow: "scroll",
      maxWidth: "50%",
      width: "100%",
      height: "",
    },
  },
}));

export default function CartNew(props: any) {
  const context: any = useContext(DataContext);
  const [width, SetWidth] = React.useState(innerWidth);

  const classes = useStyles();
  const [add_delivery_open, SetAddDeliveryOpen] = useState(false);
  const [add_vendor_open, SetAddvendorOpen] = useState(false);
  const [total_open, SetTotalOpen] = useState(false);
  const [vendorinstra, SetVendorInstr] = useState(false);
  const [coupon_modal, SetCouponModal] = useState({
    is_open: false,
  });
  const [add_contact_person, SetAddContactPerson] = useState({
    is_open: false,
  });
  const [type, SetType] = useState([
    {
      img_path: require("../../../assets/icon/box.png"),
      name: "Courier",
      disabled: false,
      value: "courier",
    },
    {
      img_path: require("../../../assets/icon/delivery-black.png"),
      name: "Hyperlocal",
      disabled: false,
      value: "hyperlocal",
    },
    {
      img_path: require("../../../assets/icon/store-black.png"),
      name: "Inshop",
      disabled: true,
      value: "inshop",
    },
    {
      img_path: require("../../../assets/icon/take-away-black.png"),
      name: "Take Away",
      disabled: true,
      value: "takeaway",
    },
    {
      img_path: require("../../../assets/icon/delivery-truck.png"),
      name: "Schedule",
      checked: false,
      disabled: true,
      value: "schedule",
    },
  ]);
  const [section_type, SetSectionType] = useState([
    { label: "All", value: "all" },
    { label: "Food", value: "food" },
    { label: "Grocery", value: "grocery" },
    { label: "Pickup Drop", value: "pickup_drop" },
    { label: "Near By Store", value: "near_by_store" },
  ]);

  const [delivery_type, SetDeliveryType] = useState([
    { label: "Hyper Local", value: "hyperlocal" },
    { label: "Courier", value: "courier" },
    { label: "Schedule", value: "schedule" },
  ]);

  const [select_delivery, SetSelectDelivery] = useState({
    is_open: false,
    value: {},
    from: "",
    index: "",
    selected_delivery_type: "",
  });

  const [delivery_point, SetDeliveryPoint] = useState([
    { label: "Single", value: "single" },
    { label: "Multiple", value: "multiple" },
  ]);

  const [coupon_apply, SetCouponApply] = useState(false);
  const [show_variant_data, SetShowVariantData] = useState({
    is_open: false,
    is_customize: false,
    product_index: "",
    product: {},
    collection_name: "",
    full_data: {},
    from: "",
  });
  const [cart_data, SetCartData] = useState([]);
  const [select_delivery_type_error_data, SetSelectDeliveryTypeErrorData] =
    useState("");
  const [delivery_type_modal, SetDeliveryTypeModal] = useState({
    is_open: false,
    value: {},
    from: "",
    index: "",
    selected_delivery_type: "",
  });
  const [hide_button, SetHideButton] = useState(false);
  const [payment_type, SetPaymentType] = useState({
    is_open: false,
    value: "",
    disabled: false,
    from: "",
    payment_index: "",
  });
  const [add_address_open, SetAddAddressOpen] = useState(false);

  useEffect(() => {});

  function SetOutletContextData(arg0: (prevalue: any) => any) {
    throw new Error("Function not implemented.");
  }

  return (
    <div className="mb-3 custom-container bg-body-color">
      <div className="row bg-fff pt-3 pb-4 position-fixed-top50px shadow-bottom border-bottom-radius">
        <div className="col-4 px-2">
          <div className="card radius-10 shadow p-2">
            <div className="cart-filter-head">Section Type</div>
            <Select
              className="cart_select"
              // styles={{
              //   menuPortal: (base) => ({ ...base, zIndex: 9999 }),
              // }}
              options={section_type}
              defaultValue={section_type[0]}
            />
          </div>
        </div>
        <div className="col-4 px-0">
          <div className="card radius-10 shadow p-2">
            <div className="cart-filter-head">Delivery Type</div>
            <Select
              className="cart_select"
              // styles={{
              //   menuPortal: (base) => ({ ...base, zIndex: 9999 }),
              // }}
              options={delivery_type}
              defaultValue={delivery_type[0]}
            />
          </div>
        </div>
        <div className="col-4 px-2">
          <div className="card radius-10 shadow p-2">
            <div className="cart-filter-head">Delivery Point</div>
            <Select
              className="cart_select"
              // styles={{
              //   menuPortal: (base) => ({ ...base, zIndex: 9999 }),
              // }}
              options={delivery_point}
              defaultValue={delivery_point[0]}
            />
          </div>
        </div>
      </div>
      <div className="px-2 mb-5 pb-5 md-mt-125px sm-mt-115px">
        <div className="card p-2 shadow radius-10 mb-3">
          <div className="d-flex align-items-center pb-3">
            <div className=" p-0 pe-2">
              <img
                src={
                  AppConfig.CDN_Image_url +
                  "62eeaf50fa0a9a609efe846632b0fc47.jpg"
                }
                className="cart-res-img"
              />
            </div>
            <div className=" p-0">
              <div className="cart-head">SuperApp Delivery</div>
            </div>
            <div className="ms-auto p-0 text-right cart-check pt-1">
              <input
                className="form-check-input   m-0" // { c_ele.checked ?
                //     : "form-check-input m-0"
                // }
                type="checkbox"
              />
            </div>
          </div>

          <div className="d-flex">
            <img
              src={
                AppConfig.CDN_Image_url + "69e22d8519c6739a9feceef2d109c256.png"
              }
              className="w-16px mt-1"
            />
            <div className="ps-2">
              <div className=" address-text text-black-color">
                Pickup{" "}
                <span className="fs-12px text-gray lowercase fw-500 pop-regular px-1">
                  from
                </span>{" "}
                Home
              </div>

              <div className="mt-1 sub-text text-gray let_space_03">
                13, Rangan 2nd Street, Karungalpalayam, Pavalatham Palayam,
                Erode, Tamil Nadu, India, 638003
              </div>
              <div className="sub-text text-black-color pt-1 let_space_03">
                Pickup contact <span className="text-dark ">Raju</span> on{" "}
                <span className="text-dark ">9874563210</span>
              </div>
            </div>
            <div className="ms-auto">
              <img
                src={
                  AppConfig.CDN_Media_url +
                  "21d3a9e5cedf31f69753210bf5015802.svg"
                }
                className="w-14px"
              />
            </div>
          </div>

          <div className="d-flex pt-2">
            <img
              src={
                AppConfig.CDN_Image_url + "69e22d8519c6739a9feceef2d109c256.png"
              }
              className="w-16px mt-1"
            />
            <div className="ps-2">
              <div className=" address-text text-black-color">
                Delivery{" "}
                <span className="fs-12px text-gray lowercase fw-500 pop-regular px-1">
                  to
                </span>{" "}
                Office
              </div>
              <div className="mt-1 sub-text text-gray let_space_03">
                13, Rangan 2nd Street, Karungalpalayam, Pavalatham Palayam,
                Erode, Tamil Nadu, India, 638003
              </div>
              <div className="sub-text text-black-color pt-1 let_space_03">
                Delivery contact <span className="text-dark ">Raju</span> on{" "}
                <span className="text-dark ">9874563210</span>
              </div>
            </div>
            <div className="ms-auto">
              <img
                src={
                  AppConfig.CDN_Media_url +
                  "21d3a9e5cedf31f69753210bf5015802.svg"
                }
                className="w-14px"
              />
            </div>
          </div>

          <div className="d-flex pt-2">
            <img
              src={
                AppConfig.CDN_Media_url + "80f0e9140732c8f8ec10c62a1de9ada2.svg"
              }
              className="w-14px mt-1"
            />
            <div className="ps-2">
              <div className=" address-text text-black-color">Delivery</div>
              <div className="mt-1 sub-text text-gray let_space_03">Books</div>
            </div>
            <div className="ms-auto">
              <img
                src={
                  AppConfig.CDN_Media_url +
                  "21d3a9e5cedf31f69753210bf5015802.svg"
                }
                className="w-14px"
              />
            </div>
          </div>

          <div className="d-flex pt-2">
            <img
              src={
                AppConfig.CDN_Media_url + "fda94e54959a8e4f0717ec95766226bd.svg"
              }
              className="w-16px mt-1"
            />
            <div className="ps-2">
              <div className=" address-text text-black-color">
                Package Value
              </div>
              <div className="mt-1 sub-text text-gray let_space_03">
                ₹ 200.00
              </div>
            </div>
            <div className="ms-auto">
              <img
                src={
                  AppConfig.CDN_Media_url +
                  "21d3a9e5cedf31f69753210bf5015802.svg"
                }
                className="w-14px"
              />
            </div>
          </div>

          {total_open ? (
            <div className="mt-2 border-top px-1 pt-2">
              <div className="d-flex align-items-center pb-1">
                <div className="v-small black-text pop-regular">Sub Total</div>
                <div className="ms-auto d-flex align-items-center">
                  <div className="strike pop-regular text-gray fs-9px pe-2">
                    ₹ 390.00
                  </div>
                  <div className="v-small black-text pop-regular">₹ 370.60</div>
                </div>
              </div>
              <div className="d-flex align-items-center pb-1">
                <div className="v-small black-text pop-regular">GST Amount</div>
                <div className="ms-auto d-flex align-items-center">
                  <div className="v-small black-text pop-regular">₹ 10.00</div>
                </div>
              </div>
              <div className="d-flex align-items-center pb-1">
                <div className="v-small black-text pop-regular">
                  Delivery Fee (5.2 km)
                </div>
                <div className="ms-auto d-flex align-items-center">
                  <div className="strike pop-regular text-gray fs-9px pe-2">
                    ₹ 14.00
                  </div>
                  <div className="v-small black-text pop-regular">₹ 10.00</div>
                </div>
              </div>
              <div className="d-flex align-items-center pb-1">
                <div className="v-small black-text pop-regular">
                  Convince Fee
                </div>
                <div className="ms-auto d-flex align-items-center">
                  <div className="strike pop-regular text-gray fs-9px pe-2">
                    ₹ 14.00
                  </div>
                  <div className="v-small black-text pop-regular">₹ 10.00</div>
                </div>
              </div>
            </div>
          ) : null}

          <div className="d-flex align-items-center border-top py-2 px-1 mt-2">
            <div
              className="d-flex align-items-center"
              onClick={() => {
                SetTotalOpen(!total_open);
              }}
            >
              <div className="fs-12 text-dark pop-font">Total</div>
              <img
                src={
                  AppConfig.CDN_Media_url +
                  "b1b871802f6048caa74336bf068322cb.svg"
                }
                className={
                  total_open ? "rotate-180 ms-2 w-10px" : "ms-2 w-10px"
                }
              />
            </div>
            <div className="ms-auto">
              <div className="fs-12 text-dark pop-font">₹ 400.60</div>
            </div>
          </div>

          {/* multiple delivery point */}

          <div className="py-2 px-1 d-flex align-items-center w-100 border-top">
            <div className="w-50  d-flex align-items-center">
              <img
                src={
                  AppConfig.CDN_Media_url +
                  "08d72b9e3d07a0472c3c7a56c4dba9d6.svg"
                }
                className="w-14px"
              />
              <div className="fs-12 ps-2 text-dark  pop-regular">
                Hyper Local
              </div>
              <img
                src={
                  AppConfig.CDN_Media_url +
                  "b1b871802f6048caa74336bf068322cb.svg"
                }
                className="ms-1 w-11px"
              />
            </div>
            <div className="w-50 end">
              <div className="fs-12 pe-2 text-dark pop-regular">
                Delivery in 40mins
              </div>
              <img
                src={
                  AppConfig.CDN_Media_url +
                  "bdd81626c3045fc4d115c50283af01ce.svg"
                }
                className="w-16px"
              />
            </div>
          </div>
          {/* multiple delivery point end */}

          {/* common instruction start */}

          <div className="border-top py-2 px-1">
            <div
              className="row align-items-center"
              onClick={() => {
                SetAddDeliveryOpen(true);
              }}
            >
              <div className="col-11 ps-0 os-instra">
                Add Delivery instructions
              </div>
              <div className="col-1 p-0 end">
                <img
                  src={
                    AppConfig.CDN_Media_url +
                    "77ff56db2e971c20be91c491e5b50729.svg"
                  }
                  className="w-14px"
                />
              </div>
            </div>
            {/* <div>
              <div className="os-instra fw-bold let_space_03">
                Delivery Instructions
              </div>
              <div className="fs-11px text-gray pop-regular let_space_03">
                Make a call before reach the delivery location
              </div>
            </div> */}
          </div>
          {/* common instruction end */}
        </div>

        <div className="card p-2 shadow radius-10 mb-3">
          <div className="row pb-2">
            <div className="col-2 p-0 pe-2">
              <img
                src={
                  AppConfig.CDN_Image_url +
                  "62eeaf50fa0a9a609efe846632b0fc47.jpg"
                }
                className="cart-res-img"
              />
            </div>
            <div className="col-9 p-0">
              <div className="cart-head">New Gowri Sankar Hotel</div>
              <div className="cart-sub">Erode Brough Road</div>
            </div>
            <div className="col-1 p-0 text-right cart-check pt-1">
              <input
                className="form-check-input   m-0" // { c_ele.checked ?
                //     : "form-check-input m-0"
                // }
                type="checkbox"
              />
            </div>
          </div>
          <div className="row pb-1 align-items-center">
            <div className="col-6 ps-0">
              <div className="d-flex">
                <img
                  src={
                    AppConfig.CDN_Image_url +
                    "e301ce3af353aab421a2f76c898a439d.png"
                  }
                  className="w-12px mt-1"
                />
                <div className="">
                  <div className="ps-2 cart-prod-name">Mexican Strips</div>
                  <div
                    className="d-flex align-items-center ps-2 cursor"
                    onClick={() => {
                      SetShowVariantData(show_variant_data);

                      // let send_data = {
                      //   product_index: acc_props.p_index_data,
                      //   product: acc_props.data,
                      //   full_data: acc_props.c_ele_data,
                      //   from: "customize",
                      // };
                      // console.log("on_custimize_click :",send_data)
                      // console.log("on_custimize_click org data:",org_cart_data)
                      // props.on_custimize_click(send_data);
                      // SetOutletContextData((prevalue: any) => {
                      //   prevalue.is_bottom_sheet_open = true;
                      //   return { ...prevalue };
                      // });
                    }}
                  >
                    <div className="customize-accrodion">Customize</div>
                    <img
                      src={
                        AppConfig.CDN_Media_url +
                        "b1b871802f6048caa74336bf068322cb.svg"
                      }
                      className="mt-1 gray-accordion-icon"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-3 p-0">
              <div className="border p-2 radius-10 d-flex align-items-center w-100">
                <div className="center w-20">
                  <img
                    src={
                      AppConfig.CDN_Media_url +
                      "1b669eb5a49a8f4778ec18b862af8324.svg"
                    }
                    className="type-img"
                  />
                </div>
                <div className="fs-12px text-dark w-80 center">1</div>
                <div className="center w-20 ">
                  <img
                    src={
                      AppConfig.CDN_Media_url +
                      "ed4c4c5f46f208f8238c965eaaa7c21f.svg"
                    }
                    className="type-img"
                  />
                </div>
              </div>
            </div>

            <div className="col-3 pe-0 ps-1 end">
              <div className="fs-12 black-text pop-font">₹ 150.60</div>
            </div>
          </div>

          <div className="row pb-1 align-items-center">
            <div className="col-6 ps-0">
              <div className="d-flex">
                <img
                  src={
                    AppConfig.CDN_Image_url +
                    "e301ce3af353aab421a2f76c898a439d.png"
                  }
                  className="w-12px mt-1"
                />
                <div className="">
                  <div className="ps-2 cart-prod-name">Mexican Strips</div>
                  <div
                    className="d-flex align-items-center ps-2 cursor"
                    onClick={() => {
                      SetShowVariantData(show_variant_data);
                    }}
                  >
                    <div className="customize-accrodion">Customize</div>
                    <img
                      src={
                        AppConfig.CDN_Media_url +
                        "b1b871802f6048caa74336bf068322cb.svg"
                      }
                      className="mt-1 gray-accordion-icon"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-3 p-0">
              <div className="border p-2 radius-10 d-flex align-items-center w-100">
                <div className="center w-20">
                  <img
                    src={
                      AppConfig.CDN_Media_url +
                      "1b669eb5a49a8f4778ec18b862af8324.svg"
                    }
                    className="type-img"
                  />
                </div>
                <div className="fs-12px text-dark w-80 center">1</div>
                <div className="center w-20 ">
                  <img
                    src={
                      AppConfig.CDN_Media_url +
                      "ed4c4c5f46f208f8238c965eaaa7c21f.svg"
                    }
                    className="type-img"
                  />
                </div>
              </div>
            </div>

            <div className="col-3 pe-0 ps-1 end">
              <div className="fs-12 black-text pop-font">₹ 150.60</div>
            </div>
          </div>

          <div className="border p-2 radius-10 bg-fff my-2">
            <div className="d-flex align-items-center">
              <img
                src={
                  AppConfig.CDN_Media_url +
                  "09346edef1ebfd53661ba4e78141c1d4.svg"
                }
                className="w-16px me-1"
              />
              <div className="loc-add fw-bold fs-10px">
                Save ₹100 and get extra cashback with GETFLAT100
              </div>
              <div className="ms-auto d-flex">
                <img
                  src={
                    AppConfig.CDN_Media_url +
                    "e98699888811e6a8f0554f6bebc69add.svg"
                  }
                  className="w-11px"
                />
              </div>
            </div>
          </div>

          {total_open ? (
            <div className="mt-2 border-bottom px-1">
              <div className="d-flex align-items-center pb-1">
                <div className="v-small black-text">Sub Total</div>
                <div className="ms-auto d-flex align-items-center">
                  <div className="strike pop-regular text-gray fs-9px pe-2">
                    ₹ 390.00
                  </div>
                  <div className="v-small black-text">₹ 370.60</div>
                </div>
              </div>
              <div className="d-flex align-items-center pb-1">
                <div className="v-small black-text">GST Amount</div>
                <div className="ms-auto d-flex align-items-center">
                  <div className="v-small black-text">₹ 10.00</div>
                </div>
              </div>
              <div className="d-flex align-items-center pb-1">
                <div className="v-small black-text">Delivery Fee (5.2 km)</div>
                <div className="ms-auto d-flex align-items-center">
                  <div className="strike pop-regular text-gray fs-9px pe-2">
                    ₹ 14.00
                  </div>
                  <div className="v-small black-text">₹ 10.00</div>
                </div>
              </div>
              <div className="d-flex align-items-center pb-1">
                <div className="v-small black-text">Convince Fee</div>
                <div className="ms-auto d-flex align-items-center">
                  <div className="strike pop-regular text-gray fs-9px pe-2">
                    ₹ 14.00
                  </div>
                  <div className="v-small black-text">₹ 10.00</div>
                </div>
              </div>
            </div>
          ) : null}

          <div className="d-flex align-items-center py-2 px-1">
            <div
              className="d-flex align-items-center"
              onClick={() => {
                SetTotalOpen(!total_open);
              }}
            >
              <div className="fs-12 text-dark pop-font">Total</div>
              <img
                src={
                  AppConfig.CDN_Media_url +
                  "b1b871802f6048caa74336bf068322cb.svg"
                }
                className={
                  total_open ? "rotate-180 ms-2 w-10px" : "ms-2 w-10px"
                }
              />
            </div>
            <div className="ms-auto">
              <div className="fs-12 text-dark pop-font">₹ 400.60</div>
            </div>
          </div>

          {/* multiple delivery point */}

          {/* {!add_address_open ? */}
          <div className="">
            <div
              className="py-2 px-1 d-flex align-items-center w-100 "
              // onClick={() => {
              //   SetAddAddressOpen(true);
              // }}
            >
              <div className="fs-12 text-dark pop-font-light pe-1 let_space_03">
                Add Delivery Address
              </div>
              <div className="p-0 ms-auto">
                <img
                  src={
                    AppConfig.CDN_Media_url +
                   "e98699888811e6a8f0554f6bebc69add.svg"
                  }
                  className="w-14px"
                />
              </div>
              </div>

              <div className="py-2 px-1 d-flex align-items-center w-100 border-top">
                <div className="fs-12 text-gray pop-font-light pe-1 let_space_03">
                  Contact
                </div>

                <div className="fs-12 pe-1 celias-font text-dark">Myself</div>

                <div
                  className="ms-auto"
                  onClick={() => {
                    SetAddContactPerson({
                      is_open: true,
                    });
                  }}
                >
                  <img
                    src={
                      AppConfig.CDN_Media_url +
                      "21d3a9e5cedf31f69753210bf5015802.svg"
                    }
                    className="w-14px"
                  />
                </div>
              </div>
          </div>
          {/* :                    */}
          <div className="py-2 px-1 px-0 d-flex align-items-center w-100">
            <div className="d-flex align-items-center  ps-0">
              <div className="fs-12 text-gray pop-font-light pe-1 let_space_03">
                Delivering to
              </div>
              <div className="fs-12 pe-1 celias-font text-dark">HOME</div>
              <img
                src={
                  AppConfig.CDN_Media_url +
                  "b1b871802f6048caa74336bf068322cb.svg"
                }
                className=" ms-1 w-11px"
              />
            </div>
            <div className="ms-auto">
              <img
                src={
                  AppConfig.CDN_Media_url +
                  "21d3a9e5cedf31f69753210bf5015802.svg"
                }
                className="w-14px"
              />
            </div>
          </div>
          {/* } */}

          {/* accordion open */}
          <div className="fs-11px text-gray pop-regular let_space_03 px-1 pb-2">
            Erode Junction, Chennimalai Road, Railway Colony, Erode, Erode,
            Tamil Nadu, India, 638002
          </div>

          <div className="py-2 px-1 d-flex align-items-center w-100 border-top">
            <div className="fs-12 text-gray pop-font-light pe-1 let_space_03">
              Contact
            </div>
            {add_contact_person ? (
              <div className="fs-12 pe-1 celias-font text-dark">
                Raju | +919874563210
              </div>
            ) : (
              <div className="fs-12 pe-1 celias-font text-dark">Myself</div>
            )}

            <div
              className="ms-auto"
              onClick={() => {
                SetAddContactPerson({
                  is_open: true,
                });
              }}
            >
              <img
                src={
                  AppConfig.CDN_Media_url +
                  "21d3a9e5cedf31f69753210bf5015802.svg"
                }
                className="w-14px"
              />
            </div>
          </div>
          {/* accordion end */}

          {/* multiple delivery point end */}

          {/* common instruction start */}
          <div className="py-2 px-1 d-flex align-items-center w-100 border-top">
            <div
              className="w-50  d-flex align-items-center"
              onClick={() => {
                SetSelectDelivery({
                  is_open: true,
                  value: {},
                  from: "",
                  index: "",
                  selected_delivery_type: "",
                });
              }}
            >
              <img
                src={
                  AppConfig.CDN_Media_url +
                  "08d72b9e3d07a0472c3c7a56c4dba9d6.svg"
                }
                className="w-14px"
              />
              <div className="fs-11px ps-2 text-dark loc-head">Hyper Local</div>
              <img
                src={
                  AppConfig.CDN_Media_url +
                  "b1b871802f6048caa74336bf068322cb.svg"
                }
                className="ms-1 w-11px"
              />
            </div>
            <div className="w-50 end">
              <div className="fs-11px pe-2 text-dark loc-head">
                Delivery in 40mins
              </div>
              <img
                src={
                  AppConfig.CDN_Media_url +
                  "bdd81626c3045fc4d115c50283af01ce.svg"
                }
                className="w-16px"
              />
            </div>
          </div>
          <div className="border-top py-2 px-1">
            {vendorinstra ? (
              <div>
                <div className="os-instra fw-bold let_space_03">
                  Vendor Instructions
                </div>
                <div className="fs-11px text-gray pop-regular let_space_03">
                  Make a dish extra spicy and extra sauce
                </div>
              </div>
            ) : (
              <div
                className="row align-items-center"
                onClick={() => {
                  SetAddvendorOpen(true);
                }}
              >
                <div className="col-11 ps-0 os-instra">
                  Add vendor instructions
                </div>
                <div className="col-1 p-0 end">
                  <img
                    src={
                      AppConfig.CDN_Media_url +
                      "77ff56db2e971c20be91c491e5b50729.svg"
                    }
                    className="w-14px"
                  />
                </div>
              </div>
            )}
          </div>

          <div className="border-top py-2 px-1">
            <div
              className="row align-items-center"
              onClick={() => {
                SetAddDeliveryOpen(true);
              }}
            >
              <div className="col-11 ps-0 os-instra">
                Add Delivery instructions
              </div>
              <div className="col-1 p-0 end">
                <img
                  src={
                    AppConfig.CDN_Media_url +
                    "77ff56db2e971c20be91c491e5b50729.svg"
                  }
                  className="w-14px"
                />
              </div>
            </div>
            {/* <div>
              <div className="os-instra fw-bold let_space_03">
                Delivery Instructions
              </div>
              <div className="fs-11px text-gray pop-regular let_space_03">
                Make a call before reach the delivery location
              </div>
            </div> */}
          </div>
          {/* common instruction end */}
        </div>

        {/* single delivery point */}

        <div className="card p-2 shadow radius-10 mb-2">
          <div className="py-2 px-1 px-0 d-flex align-items-center w-100">
            <div className="d-flex align-items-center  ps-0">
              <div className="fs-12 text-gray pop-font-light pe-1 let_space_03">
                Delivering to
              </div>
              <div className="fs-12 pe-1 celias-font text-dark">HOME</div>
              <img
                src={
                  AppConfig.CDN_Media_url +
                  "b1b871802f6048caa74336bf068322cb.svg"
                }
                className=" ms-1 w-11px"
              />
            </div>
            <div className="ms-auto">
              <img
                src={
                  AppConfig.CDN_Media_url +
                  "21d3a9e5cedf31f69753210bf5015802.svg"
                }
                className="w-14px"
              />
            </div>
          </div>

          {/* accordion open */}
          <div className="fs-11px text-gray pop-regular let_space_03 px-1 pb-2">
            Erode Junction, Chennimalai Road, Railway Colony, Erode, Erode,
            Tamil Nadu, India, 638002
          </div>

          <div className="py-2 px-1 d-flex align-items-center w-100 border-top">
            <div className="fs-12 text-gray pop-font-light pe-1 let_space_03">
              Contact
            </div>
            {add_contact_person ? (
              <div className="fs-12 pe-1 celias-font text-dark let_space_03">
                Raju | +919874563210
              </div>
            ) : (
              <div className="fs-12 pe-1 celias-font text-dark">Myself</div>
            )}

            <div
              className="ms-auto"
              onClick={() => {
                SetAddContactPerson({
                  is_open: true,
                });
              }}
            >
              <img
                src={
                  AppConfig.CDN_Media_url +
                  "21d3a9e5cedf31f69753210bf5015802.svg"
                }
                className="w-14px"
              />
            </div>
          </div>
          {/* accordion end */}
        </div>
        {/* single delivery point */}

        <div className="card px-2 py-3 shadow radius-10 mb-2">
          <div className="d-flex align-items-cener w-100">
            <div className="cart-head w-50">Payment Type</div>
            <div
              className="w-50  end"
              onClick={() => {
                SetPaymentType({
                  is_open: true,
                  // value: c_ele.payment_type,
                  // payment_index: c_index,
                  // from: "vendor_products",
                  // disabled: !c_ele.cod_available,
                  value: "",
                  payment_index: "",
                  from: "",
                  disabled: false,
                });
              }}
            >
              <div className="fs-11px px-2 text-black pop-font fw-600 let_space_03">
                Online
              </div>
              <img
                src={
                  AppConfig.CDN_Media_url +
                  "b1b871802f6048caa74336bf068322cb.svg"
                }
                className="w-11px"
              />
            </div>
          </div>
        </div>

        {coupon_apply ? (
          <div className="card p-2 shadow radius-10 mb-2">
            <div className="d-flex align-items-center w-100">
              <img
                src={
                  AppConfig.CDN_Image_url +
                  "e5a91cba09e5c6954ff2df30f01e5bff.png"
                }
                className=" me-2 w-30px"
              />
              <div className="">
                <div className="d-flex align-items-center">
                  <div className="coupon-name pe-1">Supercoupon</div>
                  <div className="text-black-color pop-font fs-13px">
                    applied
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <div className="coupon-name pe-1 fs-12">₹50</div>
                  <div className="text-gray fs-12 pop-font">Coupon savings</div>
                </div>
              </div>
              <div
                className="v-small text-gray pop-regular ms-auto"
                onClick={() => {
                  SetCouponApply(false);
                }}
              >
                Remove
              </div>
            </div>
          </div>
        ) : (
          <div className="card px-2 py-3 shadow radius-10 mb-2">
            <div className="d-flex align-items-center w-100">
              <img
                src={
                  AppConfig.CDN_Media_url +
                  "09346edef1ebfd53661ba4e78141c1d4.svg"
                }
                className=" me-2 w-20px"
              />
              <div className="">
                <div className="d-flex align-items-center">
                  <div className="cart-head">Apply Coupon</div>
                </div>
              </div>
              <div
                className="ms-auto d-flex align-items-center"
                onClick={() => {
                  SetCouponModal({
                    is_open: true,
                  });
                }}
              >
                <div className="fs-11px px-2 text-black pop-font fw-600 let_space_03">
                  Choose
                </div>
                <img
                  src={
                    AppConfig.CDN_Media_url +
                    "e98699888811e6a8f0554f6bebc69add.svg"
                  }
                  className="w-11px"
                />
              </div>
            </div>
          </div>
        )}

        <div className="card px-2 py-3 mb-2 radius-10 shadow">
          <div className="center">
            <img
              src={
                AppConfig.CDN_Image_url + "d765198e4a07c16289ffd296f69e9ced.png"
              }
              width={18}
            />
            <div className="coupon-name px-1 fs-12 text-tranform-none">
              You save ₹39.40 in this order
            </div>
          </div>
        </div>

        <div className="card px-2 py-3 shadow radius-10 mb-5">
          <div className="cart-head w-50 pb-2">Order Summary</div>
          <div className="pb-2  ">
            <div className="d-flex align-items-center pb-1">
              <div className="v-small black-text">Sub Total</div>
              <div className="ms-auto d-flex align-items-center">
                <div className="strike pop-regular text-gray fs-9px pe-2">
                  ₹ 390.00
                </div>
                <div className="v-small black-text">₹ 370.60</div>
              </div>
            </div>
            <div className="d-flex align-items-center pb-1">
              <div className="v-small black-text">GST Amount</div>
              <div className="ms-auto d-flex align-items-center">
                <div className="v-small black-text">₹ 10.00</div>
              </div>
            </div>
            <div className="d-flex align-items-center pb-1">
              <div className="v-small black-text">Delivery Fee (5.2 km)</div>
              <div className="ms-auto d-flex align-items-center">
                <div className="strike pop-regular text-gray fs-9px pe-2">
                  ₹ 14.00
                </div>
                <div className="v-small black-text">₹ 10.00</div>
              </div>
            </div>
            <div className="d-flex align-items-center pb-1">
              <div className="v-small black-text">Convince Fee</div>
              <div className="ms-auto d-flex align-items-center">
                <div className="strike pop-regular text-gray fs-9px pe-2">
                  ₹ 14.00
                </div>
                <div className="v-small black-text">₹ 10.00</div>
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center border-top py-1">
            <div className="fs-13px text-dark pop-font">Grand Total</div>
            {/* <img
                src={
                  AppConfig.CDN_Media_url +
                  "b1b871802f6048caa74336bf068322cb.svg"
                }
                className="ms-2 w-12px"
              /> */}
            <div className="ms-auto">
              <div className="fs-13px text-dark pop-font">₹ 400.60</div>
            </div>
          </div>
        </div>
      </div>

      <div className="p-2 bottom-fixed bg-none w-100">
        <button
          className="primary-button w-100"
          // onClick={() => {
          // }}
        >
          Proceed to pay
        </button>
      </div>

      {/* dialog */}

      <Dialog
        classes={{ container: classes.root, paper: classes.paper }}
        className="center"
        open={add_delivery_open}
        onClose={() => {
          SetAddDeliveryOpen(false);
        }}
      >
        <div className="row align-items-center py-3 px-2">
          <div className="col-10">
            <div className="os-dialog-head">Delivery Instructions</div>
          </div>
          <div
            className="col-2 end"
            onClick={() => {
              SetAddDeliveryOpen(false);
            }}
          >
            <img
              src={
                AppConfig.CDN_Media_url + "f0e8463e68a70f6e4a7ae696c626f8aa.svg"
              }
              className="w-25px"
            />
          </div>
        </div>
        <div className="text-center p-3">
          <textarea
            className="input-textarea"
            placeholder="eg: Just knock the door"
          ></textarea>
          <div className="mt-3 os-save-btn">Save Instruction</div>
        </div>
      </Dialog>

      <Dialog
        classes={{ container: classes.root, paper: classes.paper }}
        className="center"
        open={add_vendor_open}
        onClose={() => {
          SetAddvendorOpen(false);
          SetVendorInstr(true);
        }}
      >
        <div className="row align-items-center py-3 px-2">
          <div className="col-10">
            <div className="os-dialog-head">Vendor Instructions</div>
          </div>
          <div
            className="col-2 end"
            onClick={() => {
              SetAddvendorOpen(false);
              SetVendorInstr(true);
            }}
          >
            <img
              src={
                AppConfig.CDN_Media_url + "f0e8463e68a70f6e4a7ae696c626f8aa.svg"
              }
              className="w-25px"
            />
          </div>
        </div>
        <div className="text-center p-3">
          <textarea
            className="input-textarea"
            placeholder="eg: Make a spicy food"
          ></textarea>
          <div
            className="mt-3 os-save-btn"
            onClick={() => {
              SetAddvendorOpen(false);
              SetVendorInstr(true);
            }}
          >
            Save Instruction
          </div>
        </div>
      </Dialog>

      <Dialog
        classes={{ container: classes.root, paper: classes.paper }}
        className="center"
        open={add_contact_person.is_open}
        onClose={() => {
          SetAddContactPerson({ is_open: false });
        }}
      >
        <div className="p-3 shadow ">
          <div className="center d-md-none d-sm-block pb-1">
            <hr className="hr-drawer" />
          </div>
          <div className="text-center">
            <div className="loc-head fs-16px">
              Are you ordering for someone else?
            </div>
            <div className="od-id fw-500 text-black">
              Add contact person phone number for this task to directly
              coordinate with them
            </div>
          </div>

          {/* <div className=" d-flex align-items-center">
            <img
              src={require("../../../assets/svg/location-call.svg")}
              className="w-20px"
            />
            <div className="address-sub-text fs-10px ">
              We will directly coordinate with the contact person using this
              phone number
            </div>
          </div> */}

          <div className="text-center ">
            <div className="position-relative mt-3">
              <input type="text" className="outside" required />
              <span className="floating-label-outside">
                Add contact person name
              </span>
            </div>

            <div className="position-relative mt-3">
              <input type="number" className="outside" required />
              <span className="floating-label-outside">Add phone number</span>
            </div>

            <div
              className="mt-4 light-gray-btn"
              onClick={() => {
                SetAddvendorOpen(false);
              }}
            >
              Yes, add contact person details
            </div>
            <div
              className="mt-3 light-primary-btn"
              onClick={() => {
                SetAddvendorOpen(false);
              }}
            >
              No, it's for me
            </div>
          </div>
        </div>
      </Dialog>

      <Dialog
        classes={{ container: classes.root, paper: classes.paper }}
        className="center"
        open={select_delivery.is_open}
        onClose={() => {
          SetSelectDelivery({
            is_open: false,
            value: {},
            from: "",
            index: "",
            selected_delivery_type: "",
          });
          SetSelectDeliveryTypeErrorData("");
        }}
      >
        <div className=" shadow ">
          <div className="center d-md-none d-sm-block pb-1 pt-3">
            <hr className="hr-drawer" />
          </div>
          <div className="text-center px-3">
            <div className="loc-head fs-16px">Select delivery type</div>
            <div className="od-id fw-500 text-black">
              Choose your preferred delivery type
            </div>
          </div>

          <div className="text-center py-3">
            <div className="align-center d-flex px-1 pb-2 pt-3">
              {type?.map((data, typeindex) => (
                <div className="w-20per p-0 cursor text-center" key={typeindex}>
                  {/* Skeleton start */}
                  {/* <div className="p-2">
                    <Skeleton height={26} width={26} />
                    <div className="pt-2">
                      <Skeleton height={10} width={50} />
                    </div>
                  </div> */}
                  {/* Skeleton end */}

                  <div
                    className={
                      data.value == delivery_type_modal.selected_delivery_type
                        ? "p-2 bg-lightblue radius"
                        : "p-2"
                    }
                    onClick={() => {
                      // typeSelect(typeindex);
                      if (!data.disabled) {
                        if (data.value == "hyperlocal") {
                          if (
                            context.app_data.selected_delivery_address
                              .latitude != "" &&
                            context.app_data.selected_delivery_address
                              .longitude != ""
                          ) {
                            SetDeliveryTypeModal((prevValue: any) => {
                              prevValue.selected_delivery_type = data.value;
                              return { ...prevValue };
                            });
                            SetSelectDeliveryTypeErrorData("");
                            SetHideButton(false);
                          } else {
                            // toast(
                            //   "Currently Selected Address is not Supported for Hyper Local"
                            // );
                            SetDeliveryTypeModal((prevValue: any) => {
                              prevValue.selected_delivery_type = data.value;
                              return { ...prevValue };
                            });
                            SetHideButton(true);
                            SetSelectDeliveryTypeErrorData(
                              "Currently Selected Address don't have location information for Hyper Local Delivery"
                            );
                          }
                        }
                        if (data.value == "courier") {
                          if (
                            context.app_data.selected_delivery_address
                              .pincode != ""
                          ) {
                            SetDeliveryTypeModal((prevValue: any) => {
                              prevValue.selected_delivery_type = data.value;
                              return { ...prevValue };
                            });
                            SetSelectDeliveryTypeErrorData("");
                            SetHideButton(false);
                          } else {
                            SetDeliveryTypeModal((prevValue: any) => {
                              prevValue.selected_delivery_type = data.value;
                              return { ...prevValue };
                            });
                            SetHideButton(true);
                            SetSelectDeliveryTypeErrorData(
                              "Currently Selected Address don't have pincode information for Courier Delivery"
                            );
                          }
                        }
                        // SetDeliveryTypeModal((prevValue: any) => {
                        //   prevValue.selected_delivery_type = data.value;
                        //   return { ...prevValue };
                        // });
                      }
                    }}
                  >
                    <img
                      src={data.img_path}
                      className={
                        data.disabled
                          ? "img-opacity typeimg h-26px"
                          : "typeimg h-26px"
                      }
                    />
                    <p
                      className={
                        data.disabled
                          ? "pt-2 text-gray fs-10px capitalize"
                          : "pt-2 text-black fs-10px capitalize"
                      }
                    >
                      {data.name}
                    </p>
                  </div>
                </div>
              ))}
            </div>
            {select_delivery_type_error_data ? (
              <p className="pt-3 px-2 text-center text-red v-small">
                {select_delivery_type_error_data}
              </p>
            ) : null}

            <div className="px-2">
              <div
                className="mt-3 light-primary-btn"
                onClick={() => {
                  SetSelectDelivery({
                    is_open: false,
                    value: {},
                    from: "",
                    index: "",
                    selected_delivery_type: "",
                  });
                }}
              >
                Change
              </div>
            </div>
          </div>
        </div>
      </Dialog>

      {/* Select Payment Type */}
      <Dialog
        open={payment_type.is_open}
        onClose={() => {
          SetPaymentType({
            is_open: false,
            value: "",
            disabled: false,
            from: "",
            payment_index: "",
          });
        }}
        classes={{ container: classes.root, paper: classes.paper }}
      >
        <div className="pb-4">
          <div className="center d-md-none d-sm-block pb-1 pt-3">
            <hr className="hr-drawer" />
          </div>
          <div className="text-center px-3">
            <div className="loc-head fs-16px">Select payment type</div>
            <div className="od-id fw-500 text-black">
              Choose your preferred payment type
            </div>
          </div>
          {/* <div className="px-3 pt-4 pb-2 d-flex align-items-center w-100">
            <h6 className="mb-0 fw-500">Select Payment Type</h6>
            <div
              className="ms-auto cursor"
              onClick={() => {
                SetPaymentType({
                  is_open: false,
                  value: "",
                  disabled: false,
                  from: "",
                  payment_index: "",
                });
              }}
            >
              <img
                src={
                  AppConfig.CDN_Media_url +
                  "345876f3f496361d5d2e94cc199d5695.svg"
                }
                className="w-18px"
              />
            </div>
          </div> */}

          <div className="my-2">
            <div className="row align-items-center pt-2 w-100">
              <div className="col-6 col-md-2 px-2">
                <label
                  // className={
                  //   payment_type.disabled
                  //     ? "border px-2 py-2 bg-eee radius w-100 text-center"
                  //     : payment_type.value == "1"
                  //     ? "px-2 py-2 other radius w-100 text-center"
                  //     : "border px-2 py-2 bg-fff radius w-100  text-center"
                  // }
                  className={
                    payment_type.value == "1"
                      ? "px-2 py-2 other radius w-100 text-center"
                      : payment_type.disabled
                      ? "border px-2 py-2 bg-eee radius w-100 text-center"
                      : "border px-2 py-2 bg-fff radius w-100  text-center"
                  }
                >
                  <input
                    type="radio"
                    name="payment"
                    className="input-d-none"
                    checked={payment_type.value == "1" ? true : false}
                    disabled={false}
                    onChange={(e) => {
                      console.log("payment_type.value :", payment_type.value);
                      SetPaymentType((pervalue) => {
                        pervalue.value = 1;
                        return { ...pervalue };
                      });
                    }}
                  />
                  <img
                    src={require("../../../assets/icon/online-pay.png")}
                    className={
                      payment_type.value != "1"
                        ? "img-opacity typeimg h-26px"
                        : "typeimg h-26px"
                    }
                  />
                  <p
                    className={
                      payment_type.value != "1"
                        ? "pt-2 text-gray capitalize"
                        : "pt-2 text-black capitalize"
                    }
                  >
                    Online
                  </p>
                </label>
              </div>

              {/* {!payment_type.disabled ? ( */}
              <div className="col-6 col-md-2 px-2">
                <label
                  className={
                    payment_type.disabled
                      ? "border px-2 py-2 bg-eee radius w-100 text-center"
                      : payment_type.value == "2"
                      ? "px-2 py-2 other radius w-100 text-center"
                      : "border px-2 py-2 bg-fff radius w-100  text-center"
                  }
                >
                  <input
                    type="radio"
                    name="payment"
                    className="input-d-none"
                    checked={payment_type.value == "2" ? true : false}
                    disabled={payment_type.disabled}
                    onChange={(e) => {
                      if (!payment_type.disabled) {
                        SetPaymentType((pervalue) => {
                          pervalue.value = 2;
                          return { ...pervalue };
                        });
                      }
                    }}
                  />
                  <img
                    src={require("../../../assets/icon/cod.png")}
                    className={
                      payment_type.value != "2"
                        ? "img-opacity typeimg h-26px"
                        : "typeimg h-26px"
                    }
                  />
                  <p
                    className={
                      payment_type.value != "2"
                        ? "pt-2 text-gray capitalize"
                        : "pt-2 text-black capitalize"
                    }
                  >
                    {payment_type.disabled
                      ? "Cash On Delivery Not Available"
                      : "Cash On Delivery"}
                  </p>
                </label>
              </div>
              {/* ) : null} */}
            </div>
          </div>

          <div className="px-2">
            <div
              className="mt-3 light-primary-btn center"
              onClick={() => {
                SetPaymentType({
                  is_open: false,
                  value: "",
                  disabled: false,
                  from: "",
                  payment_index: "",
                });
              }}
            >
              Done
            </div>
          </div>
        </div>

        {/*visible content  */}
        {/* <div
          className="custom-shadow text-white bg-prime1 btn py-3 radius-none cursor w-100 align-items-center "
          onClick={() => {
            // SetCheckoutData((prevalue: any) => {
            //   prevalue.vendor_products[
            //     payment_type.payment_index
            //   ].payment_type = payment_type.value;
            //   return { ...prevalue };
            // });
            console.log("payment_type :", payment_type);
            //
            if (payment_type.from == "vendor_products") {
              SetCheckoutData((prevalue: any) => {
                let send_data = [];
                prevalue.vendor_products[
                  payment_type.payment_index
                ].payment_type = payment_type.value;
                prevalue?.vendor_products?.map((ele, index) => {
                  let id_value = [];
                  ele?.products?.map((p_ele: any) => {
                    id_value.push(p_ele.cart_id);
                  });
                  let push_value = {
                    ids: id_value,
                    delivery_address:
                      context.app_data.selected_delivery_address.id,
                    payment_type: ele.payment_type,
                    delivery_type: ele.delivery_type,
                  };
                  console.log("push_value :", push_value);
                  send_data.push(push_value);
                });
                // prevalue.pickup_drop[payment_type.payment_index].payment_type =
                //   payment_type.value;
                prevalue?.pickup_drop?.map((ele: any, index: any) => {
                  // let id_value = [];
                  // ele.products.map((p_ele: any) => {
                  //   id_value.push(p_ele.cart_id);
                  // });
                  let push_value = {
                    id: ele.cart_id,
                    type: "pickupdrop",
                    payment_type: ele.payment_type,
                  };
                  console.log("push_value :", push_value);
                  send_data.push(push_value);
                });
                console.log("prevalue :", prevalue);
                console.log("send_data in :", send_data);
                get_checkout(send_data);
                return { ...prevalue };
              });
            }
            if (payment_type.from == "pickupdrop") {
              SetCheckoutData((prevalue: any) => {
                let send_data = [];
                // prevalue.vendor_products[
                //   payment_type.payment_index
                // ].payment_type = payment_type.value;
                prevalue?.vendor_products?.map((ele, index: any) => {
                  let id_value = [];
                  ele?.products?.map((p_ele: any) => {
                    id_value.push(p_ele.cart_id);
                  });
                  let push_value = {
                    ids: id_value,
                    delivery_address:
                      context.app_data.selected_delivery_address.id,
                    payment_type: ele.payment_type,
                    delivery_type: ele.delivery_type,
                  };
                  console.log("push_value :", push_value);
                  send_data.push(push_value);
                });
                prevalue.pickup_drop[payment_type.payment_index].payment_type =
                  payment_type.value;
                prevalue?.pickup_drop?.map((ele: any, index: any) => {
                  // let id_value = [];
                  // ele.products.map((p_ele: any) => {
                  //   id_value.push(p_ele.cart_id);
                  // });
                  let push_value = {
                    id: ele.cart_id,
                    type: "pickupdrop",
                    payment_type: ele.payment_type,
                  };
                  console.log("push_value :", push_value);
                  send_data.push(push_value);
                });
                console.log("prevalue :", prevalue);
                console.log("send_data in pickupdrop :", send_data);
                get_checkout(send_data);
                return { ...prevalue };
              });

              // send_data.push({
              //   id: ele.id,
              //   type: "pickupdrop",
              //   payment_type: "1",
              // });
            }
            //
            // console.log("send_data :",send_data)
            SetPaymentType({
              is_open: false,
              payment_index: "",
              disabled: false,
              from: "",
              value: "",
            });
          }}
        >
          Done
        </div> */}
      </Dialog>

      <CouponModal
        open={coupon_modal.is_open}
        close={(data: any) => {
          console.log("Coupon Modal data :", data);
          SetCouponModal({
            is_open: false,
          });
          SetCouponApply(true);
        }}
      />

      {/* {show_variant_data.is_open ? (
        width < 720 ? (
          <div className="variant-sheet">
            <SwipeableBottomSheet
              overflowHeight={0}
              style={{ zIndex: 1300 }}
              open={show_variant_data.is_open}
              onClose={() => {
                console.log("SwipeableBottomSheet onClose");
                // SetBottomSwipe({ open: false, value: '' })
                SetShowVariantData((preValue: any) => {
                  // preValue.from = "";
                  preValue.is_customize = false;
                  preValue.product_index = "";
                  preValue.product = {};
                  preValue.is_open = false;
                  preValue.collection_name = "";
                  preValue.full_data = {};
                  return { ...preValue };
                });
                SetOutletContextData((prevalue: any) => {
                  prevalue.is_bottom_sheet_open = false;
                  return { ...prevalue };
                });
              }}
              onChange={() => {
                console.log("SwipeableBottomSheet onChange");
                // SetBottomSwipe({ open: false, value: '' })
                SetShowVariantData((preValue: any) => {
                  // preValue.from = "";
                  preValue.is_customize = false;
                  preValue.product_index = "";
                  preValue.product = {};
                  preValue.is_open = false;
                  preValue.collection_name = "";
                  preValue.full_data = {};
                  return { ...preValue };
                });
                SetOutletContextData((prevalue: any) => {
                  prevalue.is_bottom_sheet_open = false;
                  return { ...prevalue };
                });
                // SetCartData((prevalue)=>{
                //   console.log("SwipeableBottomSheet onChange SetCartData prevalue :",prevalue);
                //   prevalue = prevalue;
                //   return prevalue
                // })
                if (!show_variant_data.is_open) {
                  let send_data = {
                    section_slug: search_params.get("v"),
                    hide_loading: true,
                  };
                  get_cart(send_data);
                }
              }}
            >
              <VariantsModule
                from="bottom_sheet"
                // click_from={show_variant_data.from}
                is_customize={show_variant_data.is_customize}
                is_open={show_variant_data.is_open}
                selected_product={show_variant_data.product}
                selected_product_index={show_variant_data.product_index}
                collection_name={show_variant_data.collection_name}
                full_data={show_variant_data.full_data}
                props_from={show_variant_data.from}
                on_vs_change={(data) => {
                  console.log("on_vs_change data :", data);
                  if (!show_variant_data.is_customize) {
                    SetShowVariantData((preValue: any) => {
                      preValue?.product?.variant_specifications[
                        data.specify_index
                      ]?.items?.map((item: any, item_index: any) => {
                        if (item_index == data.item_index) {
                          item.checked = true;
                        } else {
                          item.checked = false;
                        }
                      });
                      return { ...preValue };
                    });
                  } else {
                    SetCartData((prevalue: any) => {
                      let section_index = prevalue.section_products.findIndex(
                        (ele) =>
                          ele.slug ==
                          show_variant_data.full_data.section_slug_value
                      );
                      if (section_index != -1) {
                        let vendor_index = prevalue.section_products[
                          section_index
                        ].vendors.findIndex(
                          (ele) =>
                            ele.seller_slug ==
                            show_variant_data.full_data.seller_slug
                        );
                        if (vendor_index != -1) {
                          let product_index = prevalue.section_products[
                            section_index
                          ].vendors[vendor_index].products.findIndex(
                            (ele) =>
                              ele.cart_id == show_variant_data.product.cart_id
                          );
                          if (product_index != -1) {
                            prevalue?.section_products[section_index]?.vendors[
                              vendor_index
                            ]?.products[product_index]?.variant_specifications[
                              data.specify_index
                            ]?.items?.map((item: any, item_index: any) => {
                              if (item_index == data.item_index) {
                                item.checked = true;
                              } else {
                                item.checked = false;
                              }
                            });
                          }
                        }
                      }
                      return { ...prevalue };
                    });
                    // SetCartData((preValue: any) => {
                    //   preValue.map((p_ele: any) => {
                    //     if (p_ele.cart_id == show_variant_data.product.cart_id) {
                    //       p_ele.variant_specifications[
                    //         data.specify_index
                    //       ].items.map((item: any, item_index: any) => {
                    //         if (item_index == data.item_index) {
                    //           item.checked = true;
                    //         } else {
                    //           item.checked = false;
                    //         }
                    //       });
                    //     }
                    //   });
                    //   return [...preValue];
                    // });
                  }
                }}
                on_add_to_cart={(data) => {
                  console.log("on_add_to_cart data :", data);
                  // SetMainSampleData((preValue: any) => {
                  //   Object.keys(preValue).map((c_name) => {
                  //     preValue[c_name].products.map((p_ele: any, p_index: any) => {
                  //       let product_count_in_cart = 0;
                  //       let product_quantity_count_in_cart = 0;
                  //       data.cart_data.map((c_ele: any, c_index: any) => {
                  //         if (c_ele.product_id == p_ele.id) {
                  //           product_count_in_cart = product_count_in_cart + 1;
                  //           product_quantity_count_in_cart =
                  //             product_quantity_count_in_cart +
                  //             parseInt(c_ele.quantity);
                  //         }
                  //       });
                  //       if (product_count_in_cart > 0) {
                  //         p_ele.addbtn = true;
                  //       } else {
                  //         p_ele.addbtn = false;
                  //       }
                  //       if (product_quantity_count_in_cart > 0) {
                  //         p_ele.addcount = product_quantity_count_in_cart;
                  //       } else {
                  //         p_ele.addcount = 0;
                  //       }
                  //     });
                  //   });
                  //   return { ...preValue };
                  // });
                  SetShowVariantData((preValue: any) => {
                    // preValue.from = "";
                    preValue.is_customize = false;
                    preValue.product_index = "";
                    preValue.product = {};
                    preValue.is_open = false;
                    preValue.collection_name = "";
                    preValue.full_data = {};
                    return { ...preValue };
                  });
                  SetCartData(data.cart_data);
                }}
                on_update_cart={(data: any) => {
                  console.log("on_update_cart data :", data);
                  SetShowVariantData((preValue: any) => {
                    // preValue.from = "";
                    preValue.is_customize = false;
                    preValue.product_index = "";
                    preValue.product = {};
                    preValue.is_open = false;
                    preValue.collection_name = "";
                    preValue.full_data = {};
                    return { ...preValue };
                  });
                  SetCartData(data);
                  SetOutletContextData((prevalue: any) => {
                    prevalue.is_bottom_sheet_open = false;
                    return { ...prevalue };
                  });
                }}
              />
            </SwipeableBottomSheet>
          </div>
        ) : (
          <Drawer
            className="variant pb-0"
            anchor={"right"}
            open={show_variant_data.is_open}
            onClose={(event) => {
              // toggleDrawer(anchor, false, event)
              SetShowVariantData((preValue: any) => {
                // preValue.from = "";
                preValue.is_customize = false;
                preValue.product_index = "";
                preValue.product = {};
                preValue.is_open = false;
                preValue.collection_name = "";
                preValue.full_data = {};
                return { ...preValue };
              });
            }}
          >
            <VariantsModule
              from="drawer_sheet"
              // click_from={show_variant_data.from}
              is_customize={show_variant_data.is_customize}
              is_open={show_variant_data.is_open}
              selected_product={show_variant_data.product}
              selected_product_index={show_variant_data.product_index}
              collection_name={show_variant_data.collection_name}
              full_data={show_variant_data.full_data}
              props_from={show_variant_data.from}
              on_vs_change={(data: any) => {
                console.log("on_vs_change data :", data);
                console.log("show_variant_data :", show_variant_data);
                console.log("cart_data :", cart_data);
                if (!show_variant_data.is_customize) {
                  SetShowVariantData((preValue: any) => {
                    preValue?.product?.variant_specifications[
                      data.specify_index
                    ]?.items?.map((item: any, item_index: any) => {
                      if (item_index == data.item_index) {
                        item.checked = true;
                      } else {
                        item.checked = false;
                      }
                    });
                    return { ...preValue };
                  });
                } else {
                  SetCartData((prevalue: any) => {
                    let section_index = prevalue.section_products.findIndex(
                      (ele) =>
                        ele.slug ==
                        show_variant_data.full_data.section_slug_value
                    );
                    if (section_index != -1) {
                      let vendor_index = prevalue.section_products[
                        section_index
                      ].vendors.findIndex(
                        (ele) =>
                          ele.seller_slug ==
                          show_variant_data.full_data.seller_slug
                      );
                      if (vendor_index != -1) {
                        let product_index = prevalue.section_products[
                          section_index
                        ].vendors[vendor_index].products.findIndex(
                          (ele) =>
                            ele.cart_id == show_variant_data.product.cart_id
                        );
                        if (product_index != -1) {
                          prevalue?.section_products[section_index]?.vendors[
                            vendor_index
                          ]?.products[product_index]?.variant_specifications[
                            data.specify_index
                          ]?.items?.map((item: any, item_index: any) => {
                            if (item_index == data.item_index) {
                              item.checked = true;
                            } else {
                              item.checked = false;
                            }
                          });
                        }
                      }
                    }
                    return { ...prevalue };
                  });
                  // SetCartData((preValue: any) => {
                  // preValue.map((p_ele: any) => {
                  //   if (p_ele.cart_id == show_variant_data.product.cart_id) {
                  //     p_ele.variant_specifications[
                  //       data.specify_index
                  //     ].items.map((item: any, item_index: any) => {
                  //       if (item_index == data.item_index) {
                  //         item.checked = true;
                  //       } else {
                  //         item.checked = false;
                  //       }
                  //     });
                  //   }
                  // });
                  // return [...preValue];
                  // });
                }
              }}
              on_add_to_cart={(data: any) => {
                console.log("on_add_to_cart data :", data);
                // SetMainSampleData((preValue: any) => {
                //   Object.keys(preValue).map((c_name) => {
                //     preValue[c_name].products.map((p_ele: any, p_index: any) => {
                //       let product_count_in_cart = 0;
                //       let product_quantity_count_in_cart = 0;
                //       data.cart_data.map((c_ele: any, c_index: any) => {
                //         if (c_ele.product_id == p_ele.id) {
                //           product_count_in_cart = product_count_in_cart + 1;
                //           product_quantity_count_in_cart =
                //             product_quantity_count_in_cart +
                //             parseInt(c_ele.quantity);
                //         }
                //       });
                //       if (product_count_in_cart > 0) {
                //         p_ele.addbtn = true;
                //       } else {
                //         p_ele.addbtn = false;
                //       }
                //       if (product_quantity_count_in_cart > 0) {
                //         p_ele.addcount = product_quantity_count_in_cart;
                //       } else {
                //         p_ele.addcount = 0;
                //       }
                //     });
                //   });
                //   return { ...preValue };
                // });
                SetShowVariantData((preValue: any) => {
                  // preValue.from = "";
                  preValue.is_customize = false;
                  preValue.product_index = "";
                  preValue.product = {};
                  preValue.is_open = false;
                  preValue.collection_name = "";
                  preValue.full_data = {};
                  return { ...preValue };
                });
                SetCartData(data.cart_data);
              }}
              on_update_cart={(data) => {
                console.log("on_update_cart data :", data);
                SetShowVariantData((preValue: any) => {
                  // preValue.from = "";
                  preValue.is_customize = false;
                  preValue.product_index = "";
                  preValue.product = {};
                  preValue.is_open = false;
                  preValue.collection_name = "";
                  preValue.full_data = {};
                  return { ...preValue };
                });
                SetCartData(data);
                SetOutletContextData((prevalue: any) => {
                  prevalue.is_bottom_sheet_open = false;
                  return { ...prevalue };
                });
              }}
            />
          </Drawer>
        )
      ) : null} */}

      {show_variant_data.is_open ? (
        width < 720 ? (
          <SwipeableBottomSheet
            overflowHeight={0}
            style={{ zIndex: 1300 }}
            open={show_variant_data.is_open}
            onClose={() => {
              console.log("SwipeableBottomSheet onClose");
            }}
          >
            hi
          </SwipeableBottomSheet>
        ) : (
          <Drawer
            className="variant pb-0"
            anchor={"right"}
            open={show_variant_data.is_open}
          >
            hi
          </Drawer>
        )
      ) : null}
    </div>
  );
}
