import React, { useState, useContext } from "react";
import ImageContainer from "../ImageContainer/ImageContainer";
import { AppHelper } from "../../utils/Helper";
import { AppConfig } from "../../config";
import DataContext from "../../utils/Context/DataContext";
import QuantityButtonComponent from "../QuantityButtonComponent/QuantityButtonComponent";
import { Link } from "react-router-dom";

function ResturantCardComponent(g_props: any) {
  const context: any = useContext(DataContext);
  const Spinner = () => (
    <div className="vendor-list-loader-spinner mx-auto"></div>
  );

  const get_mrp_data = (get_data: any) => format_amount(get_data);

  function format_amount(get_data: any) {
    let g_data_value = get_data?.split(".");
    if (g_data_value != undefined) {
      return (
        "₹" +
        g_data_value[0].replace(/(\d)(?=(\d\d)+\d$)/g, "$1,") +
        (g_data_value[1] != "00" ? "." + g_data_value[1] : "")
      );
    }
  }

  const getFontSize = (textLength: any, bs: any = null) => {
    const baseSize = bs != null ? bs : 21;
    if (textLength >= baseSize) {
      textLength = baseSize - 2;
    }
    if (textLength < 8) {
      if (bs != null) {
        return `12px`;
      } else {
        return `13px`;
      }
    }
    let fontSize = baseSize - textLength;
    if (g_props?.from == "category_page" && bs == null) {
      fontSize = fontSize - 2;
    }
    return `${fontSize}px`;
  };

  return (
    <>
      <div className="col-8 p-0  cursor" onClick={() => {}}>
        <div>
          {/* <div className="d-flex align-items-center w-100">
            <img
            src={require("../../../assets/icon/veg.png")}
            className="type-img"
            />
            Best Selling
            <div className="d-flex align-items-center ms-2 b_seller">
            <FontAwesome name="star" size={10} color="orange" />
            <p className="px-1">
                <small className="text-orange fw-bold">Best Selling</small>
            </p>
            </div>
        </div> */}
          <div className="d-flex align-items-center">
            {g_props.data.hasOwnProperty("specifications")
              ? g_props.get_diet_icon
              : null}
            <div
              className="cursor ms-1"
              onClick={() => {
                //   let send_data = {
                //     brand_id: context.app_data.selected_vendor.brand_id,
                //     outlet_id:
                //       context.app_data.selected_vendor_outlet.outlet_id,
                //     checked: 0,
                //   };
                //   if (data.type == "group") {
                //     send_data.product_id = data.product_id;
                //   }
                //   if (data.type == "product") {
                //     send_data.variant_id = data.variant_id;
                //   }
                //   if (!data.favourite) {
                //     send_data.checked = 1;
                //   }
                //   let change_data = {
                //     menu_name: menu.name,
                //     index: index,
                //   };
                //   addWishlist(send_data, "product", change_data);
                g_props.add_wishlist();
              }}
            >
              {g_props.data.favourite ? (
                <img
                  src={
                    AppConfig.CDN_Media_url +
                    "eff3dd986a87c9e8224c9547708adcd4.svg"
                  }
                  className="w-20"
                />
              ) : (
                <img
                  src={
                    AppConfig.CDN_Media_url +
                    "717d8ac4005e4eda5f72bd6b5485d4be.svg"
                  }
                  className="w-20"
                />
              )}
            </div>
          </div>
          <h6
            className="pt-1 mb-1 "
            onClick={() => {
              // SetOutletContextData((prevalue: any) => {
              //   prevalue.is_bottom_sheet_open = true;
              //   return { ...prevalue };
              // });
              // SetQuickView({
              //   open: true,
              //   value: data,
              // });
              g_props.quick_view();
            }}
          >
            {g_props.data.name}
          </h6>
          <p
            className={
              g_props.data.description != null
                ? "marg celias-font h-33px"
                : "h-33px"
            }
            onClick={() => {
              // SetOutletContextData((prevalue: any) => {
              //   prevalue.is_bottom_sheet_open = true;
              //   return { ...prevalue };
              // });
              // SetQuickView({
              //   open: true,
              //   value: data,
              // });
              g_props.quick_view();
            }}
          >
            {g_props.data.description}
          </p>
          <p
            className="pt-2 h-40px"
            onClick={() => {
              // SetOutletContextData((prevalue: any) => {
              //   prevalue.is_bottom_sheet_open = true;
              //   return { ...prevalue };
              // });
              // SetQuickView({
              //   open: true,
              //   value: data,
              // });
              g_props.quick_view();
            }}
          >
            <b className="text-dark fs-7 celias-font">
              {g_props.data.hasOwnProperty("show_price_without_gst") ? (
                g_props.data.show_price_without_gst ? (
                  <>
                    {g_props.data.price_without_gst != "" &&
                    g_props.data.price_without_gst != null
                      ? "₹ " + g_props.data.price_without_gst
                      : null}
                  </>
                ) : (
                  <>
                    {g_props.data.price_with_gst != "" &&
                    g_props.data.price_with_gst != null
                      ? "₹ " + g_props.data.price_with_gst
                      : null}
                  </>
                )
              ) : null}
            </b>
            <p
              className="strike"
              style={{
                fontSize: getFontSize(
                  get_mrp_data(g_props?.data?.mrp)?.length,
                  18
                ),
              }}
            >
              {get_mrp_data(g_props?.data?.mrp)}
            </p>
          </p>
        </div>
      </div>
      <div className="col-4 p-0">
        <div
          className={
            g_props.data?.images && g_props.data?.images?.length > 0 ? "" : ""
          }
        >
          {/* image */}
          <div className="near-img" onClick={() => {}}>
            {g_props.data.hasOwnProperty("images") && (
              <>
                <div
                  onClick={() => {
                    //   SetQuickView({
                    //     open: true,
                    //     value: data,
                    //   });
                    g_props.quick_view();
                  }}
                >
                  {g_props.data.hasOwnProperty("images") ? (
                    <ImageContainer
                      src={AppHelper.imageSrc(
                        context.app_data.image_base_url,
                        g_props.data.images[0].url,
                        {
                          format: "webp",
                          width: 320,
                        }
                      )}
                      thumb={AppHelper.imageSrc(
                        context.app_data.image_base_url,
                        g_props.data.images[0].url,
                        {
                          format: "webp",
                          width: 320,
                          quality: 10,
                        }
                      )}
                      class_name={
                        g_props.data.available && g_props.get_outlet_available
                          ? "near-img "
                          : "near-img-gray"
                      }
                      onIsVisible={() => {
                        console.log("ImageContainer On Is visible");
                      }}
                    />
                  ) : (
                    <div className="near-img full center radius"></div>
                  )}
                </div>
              </>
            )}
          </div>
          {/* button */}
          {g_props.get_outlet_available ? (
            g_props.data.available ? (
              g_props.data.addbtn ? (
                <div
                  className={
                    g_props.data.hasOwnProperty("images") &&
                    g_props.data.images.length > 0
                      ? "bottom-offer "
                      : "w-100"
                  }
                >
                  <QuantityButtonComponent
                    from_page={"vendor"}
                    section_slug={context.app_data.selected_section.slug}
                    context_data={context}
                    minus={() => {
                      g_props.on_minus();
                    }}
                    pluse={() => {
                      g_props.on_pluse();
                    }}
                    addcount={g_props.data.addcount}
                    api_loading={g_props.data.api_loading}
                  />

                  {g_props.data.customize ? (
                    <p className="text-green v-small text-center cursor">
                      Customizable
                    </p>
                  ) : null}
                </div>
              ) : g_props.from == "vendor_page" ? (
                <div className="w-100">
                  {g_props.data.api_loading ? (
                    <div
                      className={
                        g_props.data.hasOwnProperty("images") &&
                        g_props.data.images.length > 0
                          ? "bottom-offer "
                          : "w-100 mb-3"
                      }
                    >
                      <div className="offer mb-2">
                        <p className="center cursor p-2">
                          <Spinner />
                        </p>
                      </div>
                      {g_props.data.customize ? (
                        <p className="text-green v-small text-center cursor">
                          Customizable
                        </p>
                      ) : null}
                    </div>
                  ) : (
                    <div
                      className={
                        g_props.data.hasOwnProperty("images") &&
                        g_props.data.images.length > 0
                          ? "bottom-offer cursor"
                          : "w-100 mb-3 cursor"
                      }
                      onClick={(event) => {
                        //123
                        g_props.on_add();
                      }}
                    >
                      <div className="offer mb-2">
                        <p className="p-2">
                          <b>Add</b>
                        </p>
                      </div>
                      {g_props.data.customize ? (
                        <p className="text-green v-small text-center cursor">
                          Customizable
                        </p>
                      ) : null}
                    </div>
                  )}
                </div>
              ) : (
                // <Link to={get_to_path(data, r_props.v_ele)}>
                <div
                  className={g_props.data.images !== "" ? "bottom-offer" : ""}
                  onClick={() => {
                    g_props.on_add_click();
                  }}
                >
                  <div className="offer p-0 d-flex align-items-center">
                    <p className="cursor p-2 text-dark celias-font w-100">
                      Add
                    </p>
                  </div>
                </div>
                // {/* </Link> */}
              )
            ) : (
              <div
                className={
                  g_props.data.hasOwnProperty("images") &&
                  g_props.data.images.length > 0
                    ? "bottom-offer"
                    : "bottom-offer w-100 mb-3"
                }
              >
                <div className="offer mb-3">
                  {g_props.data.next_available_at != "" &&
                  g_props.data.next_available_at != null ? (
                    <p className="cursor celias-font fs-7px text-gray">
                      Next Available at {g_props.data.next_available_at}
                    </p>
                  ) : (
                    <p className="cursor p-2 celias-font fs-8px w-100">
                      Unavailable
                    </p>
                  )}
                </div>
              </div>
            )
          ) : null}
        </div>
      </div>
    </>
  );
}
export default ResturantCardComponent;
