import React, { useState, useEffect, useContext } from "react";
import { Link, useOutletContext, useSearchParams } from "react-router-dom";
import DataContext from "../../../utils/Context/DataContext";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Dialog,
  makeStyles,
} from "@material-ui/core";
import Skeleton from "react-loading-skeleton";
import { AppConfig } from "../../../config";

export default function WishListUI(props: any) {
  const context = useContext(DataContext);
  const [open_accordion, SetOpenAccordion] = useState(false);

  return (
    <div className="p-3 bg-body-color mb-5 custom-container">
      <div className="card px-2 py-3 radius-10 shadow mb-3">
        <div className="d-flex align-items-center px-2 ">
          <div
            className="d-flex align-items-center cursor"
            onClick={() => {
              SetOpenAccordion(!open_accordion);
            }}
          >
            <div className="loc-head fs-15px pe-2 ps-1">Murugan Food Point</div>
            <img
              src={
                AppConfig.CDN_Media_url + "b1b871802f6048caa74336bf068322cb.svg"
              }
              className={open_accordion ? "rotate-180 w-12px" : "w-12px"}
            />
          </div>
          <div className="ms-auto pe-1">
            <img
              src={
                AppConfig.CDN_Media_url + "40c421836459cc569e228209956cfb7d.svg"
              }
              className="w-20"
            />
          </div>
        </div>

        {open_accordion ? (
          <div className="pt-3 pb-2">
            <div className="border radius-10 px-1">
              <div className="d-flex align-items-center p-2 border-bottom">
                <div className="">
                  <div className="d-flex align-items-center">
                    <img
                      src={
                        AppConfig.CDN_Image_url +
                        "e301ce3af353aab421a2f76c898a439d.png"
                      }
                      className="w-10px me-2"
                    />
                    <div className="sub-text text-black-color fs-13px">
                      Mexican strips
                    </div>
                  </div>
                  <div className="sub-text text-gray pop-regular">₹23.00</div>
                </div>
                <div className="ms-auto">
                  <img
                    src={
                      AppConfig.CDN_Media_url +
                      "40c421836459cc569e228209956cfb7d.svg"
                    }
                    className="w-20"
                  />
                </div>
              </div>

              <div className="d-flex align-items-center p-2 border-bottom">
                <div className="">
                  <div className="d-flex align-items-center">
                    <img
                      src={
                        AppConfig.CDN_Image_url +
                        "e301ce3af353aab421a2f76c898a439d.png"
                      }
                      className="w-10px me-2"
                    />
                    <div className="sub-text text-black-color fs-13px">
                      Mexican strips
                    </div>
                  </div>
                  <div className="sub-text text-gray pop-regular">₹23.00</div>
                </div>
                <div className="ms-auto">
                  <img
                    src={
                      AppConfig.CDN_Media_url +
                      "40c421836459cc569e228209956cfb7d.svg"
                    }
                    className="w-20"
                  />
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
      
      <div className="card px-2 py-3 radius-10 shadow mb-3">
        <div className="d-flex align-items-center px-2 ">
          <div
            className="d-flex align-items-center cursor"
            onClick={() => {
              SetOpenAccordion(!open_accordion);
            }}
          >
            <div className="loc-head fs-15px pe-2 ps-1">Murugan Food Point</div>
            <img
              src={
                AppConfig.CDN_Media_url + "b1b871802f6048caa74336bf068322cb.svg"
              }
              className={open_accordion ? "rotate-180 w-12px" : "w-12px"}
            />
          </div>
          <div className="ms-auto pe-1">
            <img
              src={
                AppConfig.CDN_Media_url + "40c421836459cc569e228209956cfb7d.svg"
              }
              className="w-20"
            />
          </div>
        </div>

        {open_accordion ? (
          <div className="pt-3 pb-2">
            <div className="border radius-10 px-1">
              <div className="d-flex align-items-center p-2 border-bottom">
                <div className="">
                  <div className="d-flex align-items-center">
                    <img
                      src={
                        AppConfig.CDN_Image_url +
                        "e301ce3af353aab421a2f76c898a439d.png"
                      }
                      className="w-10px me-2"
                    />
                    <div className="sub-text text-black-color fs-13px">
                      Mexican strips
                    </div>
                  </div>
                  <div className="sub-text text-gray pop-regular">₹23.00</div>
                </div>
                <div className="ms-auto">
                  <img
                    src={
                      AppConfig.CDN_Media_url +
                      "40c421836459cc569e228209956cfb7d.svg"
                    }
                    className="w-20"
                  />
                </div>
              </div>

              <div className="d-flex align-items-center p-2 border-bottom">
                <div className="">
                  <div className="d-flex align-items-center">
                    <img
                      src={
                        AppConfig.CDN_Image_url +
                        "e301ce3af353aab421a2f76c898a439d.png"
                      }
                      className="w-10px me-2"
                    />
                    <div className="sub-text text-black-color fs-13px">
                      Mexican strips
                    </div>
                  </div>
                  <div className="sub-text text-gray pop-regular">₹23.00</div>
                </div>
                <div className="ms-auto">
                  <img
                    src={
                      AppConfig.CDN_Media_url +
                      "40c421836459cc569e228209956cfb7d.svg"
                    }
                    className="w-20"
                  />
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>

    </div>
  );
}
