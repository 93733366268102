import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Link,
  useParams,
  useNavigate,
  useOutletContext,
} from "react-router-dom";
import DataContext from "../../../utils/Context/DataContext";
import { AppConfig } from "../../../config";
import { Dialog, Drawer, makeStyles } from "@material-ui/core";
import SwipeableBottomSheet from "@geomatico/react-swipeable-bottom-sheet";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("xs")]: {
      alignItems: "flex-end", // push the dialog to bottom
    },
  },
  paper: {
    // make the content full width
    [theme.breakpoints.down("xs")]: {
      margin: 0,
      maxWidth: "100%",
      width: "100%",
      overflow: "scroll",
      paddingBottom: "45px",
      maxHeight: "100% !important",
    },
    [theme.breakpoints.up("md")]: {
      overflow: "scroll",
      maxWidth: "50%",
      width: "100%",
      height: "",
    },
  },
}));

interface UploadedFile {
  id: number; // Unique ID for each file
  name: string;
  type: string;
  size: number;
  url?: string; // Optional URL for image previews
}

export default function PickupDropDashboardUI(props: any) {
  const context: any = useContext(DataContext);
  const classes = useStyles();
  const [pickup_location, SetPickupLocation] = useState(true);
  const [drop_location, SetDropLocation] = useState(false);
  const [add_contact_person, SetAddContactPerson] = useState({
    is_open: false,
  });
  const [add_vendor_open, SetAddvendorOpen] = useState(false);
  const [package_open, SetPackageOpen] = useState({
    is_open: false,
    value: "",
  });
  const [address_selected, SetAddressSelected] = useState({
    is_open: false,
    value: "",
  });
  const [contact_selected, SetContactSelected] = useState({
    is_open: false,
    value: "",
  });
  const [package_select_open, SetPackageSelectOpen] = useState({
    is_open: false,
    value: "",
  });
const [create_package, SetCreatePackage] = useState({
  is_open: false,
  value: "",
})

  // const heightRefs =useRef<HTMLDivElement[]>([]);
  const heightRefs = useRef<HTMLDivElement | null>(null);
  // heightRefs.current = [];

  // // State to store the maximum height
  const [maxHeight, setMaxHeight] = useState<number>(0);

  // // Function to add refs dynamically
  // const addToRefs = (el: HTMLDivElement) => {
  //   if (el && !heightRefs.current.includes(el)) {
  //     heightRefs.current.push(el);
  //   }
  // };

  // useEffect(() => {
  //   // Find the maximum height among all second divs initially
  //   const heights = heightRefs.current.map((div) => div.offsetHeight);
  //   const tallest = Math.max(...heights);
  //   setMaxHeight(tallest);

  //   const handleResize = () => {
  //     // Update heights only when the window is resized
  //     const updatedHeights = heightRefs.current.map((div) => div.offsetHeight);
  //     setMaxHeight(Math.max(...updatedHeights));
  //   };

  //   window.addEventListener("resize", handleResize);
  //   return () => window.removeEventListener("resize", handleResize);
  // }, []); // Only run on mount

  // const divRef = useRef<HTMLDivElement | null>(null); // Ref for the target div
  // const [divHeight, setDivHeight] = useState<number>(0); // State to store the dynamic height

  useEffect(() => {
    if (!heightRefs.current) return;

    // Function to update height
    const updateHeight = () => {
      if (heightRefs.current) {
        setMaxHeight(heightRefs.current.offsetHeight);
      }
    };

    // Initialize ResizeObserver
    const resizeObserver = new ResizeObserver(() => {
      updateHeight();
    });

    // Start observing the div
    resizeObserver.observe(heightRefs.current);

    // Set initial height
    updateHeight();

    return () => {
      // Cleanup on component unmount
      if (heightRefs.current) {
        resizeObserver.unobserve(heightRefs.current);
      }
      resizeObserver.disconnect();
    };
  }, []); // Run once on mount

  const [package_details, setPackageDetails] = useState([
    {
      name: "Food",
      img_file: "95a35d96a54ae164d7dbfceb7382a511.svg",
    },
    {
      name: "Files",
      img_file: "95a35d96a54ae164d7dbfceb7382a511.svg",
    },
    {
      name: "Stationary",
      img_file: "1260a96a4c2566c12889c057c6f7f0aa.svg",
    },
    {
      name: "Others",
      img_file: "c068dd0e8c606491a5468d804cb072d3.svg",
    },
  ]);

  const [payment_details, setPaymentDetails] = useState([
    {
      name: "N/A",
      img_file:
        AppConfig.CDN_Media_url + "1b271fabb0fec66173806bc022e640d9.svg",
    },
    {
      name: "PRE",
      img_file:
        AppConfig.CDN_Image_url + "5b7fd21f433dba19759cb577331865cc.png",
    },
    {
      name: "COD",
      img_file:
        AppConfig.CDN_Image_url + "c308c8787cbbf1c430539c362c352081.png",
    },
  ]);

  const [active_package_item, setActivePackageItem] = useState<string | null>(
    null
  );
  const [active_payment_item, setActivePaymentItem] = useState<string | null>(
    null
  );
  const [selectedFile, setSelectedFiles] = useState<UploadedFile[]>([]);
  const [uploadStatus, setUploadStatus] = useState<string>("");
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [filePreview, setFilePreview] = useState<string[]>([]);

  const handleDivClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const filesArray = Array.from(event.target.files);
      // const filesArray = Array.from(event.target.files).map((file, index) => ({
      //   id: Date.now() + index, // Unique ID using timestamp + index
      //   name: file.name,
      //   type: file.type,
      //   size: file.size,
      //   url: file.type.startsWith("image/") ? URL.createObjectURL(file) : undefined,
      // }));
      setSelectedFiles(filesArray);

      // Generate previews for image files
      const previews: string[] = filesArray
        .filter((file) => file.type.startsWith("image/"))
        .map((file) => URL.createObjectURL(file));

      setFilePreview(previews);
      // setSelectedFiles((prevFiles) => [...prevFiles, ...filesArray]);
    }
  };

  // const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   if (event.target.files && event.target.files.length > 0) {
  //     const filesArray = Array.from(event.target.files).map((file, index) => ({
  //       id: Date.now() + index, // Generate unique ID using timestamp
  //       name: file.name,
  //       type: file.type,
  //       size: file.size,
  //       url: file.type.startsWith("image/") ? URL.createObjectURL(file) : undefined,
  //     }));

  //     setSelectedFiles((prevFiles) => [...prevFiles, ...filesArray]);
  //   }
  // };

  const handleRemoveFile = (id: number) => {
    console.log("Remove: ", id);
    setSelectedFiles((prevFiles) =>
      prevFiles.filter((file) => {
        console.log("prevFiles file: ", prevFiles);
        console.log("prevFiles file: ", prevFiles.index == id);

        prevFiles.index !== id;
      })
    );
  };

  return (
    <div className="page-main mb-4 bg-body-color py-3 px-2 custom-container mb-5">
      <div className="bg-fff py-3 px-2 radius-6 shadow mb-3">
        <div className="fs-16px text-dark pop-font">Package Information</div>
        <div className="fs-11px text-light-gray pop-regular let_space_03 mt-1">
          Please enter details about packages
        </div>
        <div className="mt-3">
          <div className="fs-13px pop-font">Package Details</div>          
        </div>

        <div className="shop-div p-2 radius-6 mt-2">
          <div className="d-flex align-items-center pb-1">
            <div className="border radius-6 center bg-fff p-2">
              <div className="fs-13px uppercase text-dark">P1</div>
            </div>
            <div className="px-2">
              <div className="fs-10px text-darkgray pop-regular let_space_03 fw-bold">
                Content
              </div>
              <div className="fs-11px text-light-black pop-font let_space_03 fw-bold">
                Notebook
              </div>
            </div>
            <div className="px-2">
              <div className="fs-10px text-darkgray pop-regular let_space_05">
                Value
              </div>
              <div className="fs-11px text-light-black fw-bold">
                <span className="DMSanst">₹</span>
                <span className=" let_space_03 pop-font">200</span>
              </div>
            </div>
            <div className="ms-auto center ps-2 py-2">
            <div className="px-3 cursor">
              <img
                src={
                  AppConfig.CDN_Media_url +
                  "21d3a9e5cedf31f69753210bf5015802.svg"
                }
                className="w15px-icon"
              />
            </div>
            <div className="ps-2 py-2  cursor">
              <img
                src={
                  AppConfig.CDN_Media_url +
                  "27b1e4dbbad6d871540efbe961718c46.svg"
                }
                className="w15px-icon"
              />
            </div>
            </div>
          </div>
          <div className="bg-fff py-2 px-3 radius-6">
            <div className="text-gray fs-11px pop-regular pb-1">
              Attachments
            </div>
            <div className="d-flex align-items-center mt-2">
              <div className="attch-img-div shadow me-2">
                <img
                  src={
                    AppConfig.CDN_Image_url +
                    "f512443df8aebc613c98f5acf3275cca.png"
                  }
                />
              </div>
              <div className="attch-img-div shadow me-2">
                <img
                  src={
                    AppConfig.CDN_Image_url +
                    "d727592a461fce006f09ba169ce6751e.png"
                  }
                />
              </div>
              <div className="attch-img-div shadow me-2">
                <img
                  src={
                    AppConfig.CDN_Image_url +
                    "a2b7a893507c0e7a86e0de2c563d24fd.png"
                  }
                />
              </div>
            </div>
          </div>

          <hr className="m-0 my-2" />

          <div className="d-flex">
            <div className="me-1 me-md-3">
              <div className="fs-10px text-gray pop-regular h-24px let_space_03 d-inline-block">
                Collect Amount
              </div>
              <div className="fs-11px text-light-black fw-bold">
                <span className="DMSanst ">₹</span>1000
              </div>
            </div>
            <div className="me-1 me-md-3">
              <div className="fs-10px text-gray pop-regular h-24px let_space_03 d-inline-block">
                Payment Method
              </div>
              <div className="bg-red bg-status uppercase">pre paid</div>
            </div>
            <div className="me-1 me-md-3">
              <div className="fs-10px text-gray pop-regular h-24px let_space_03 d-inline-block">
                Payment Mode
              </div>
              <div className="bg-prime1 bg-status capitlize">online</div>
            </div>
            <div className="me-1 me-md-3">
              <div className="fs-10px text-gray pop-regular h-24px let_space_03 d-inline-block">
                Payment Status
              </div>
              <div className="bg-dark bg-status capitlize">No payments</div>
            </div>
          </div>
        </div>

        {package_open.is_open ? (
          <div className=" mt-3">
            <div className="mb-3">
              <div className="fs-16px text-dark pop-font let_space_03">
                Package 2
              </div>
              <div className="text-gray fs-10px pop-regular let_space_03 mt-1">
                Add details about this package
              </div>
            </div>
            <div className="text-black-color pop-regular fs-12px mb-2">
              What are you sending?
            </div>
            <div className="d-flex align-items-center">
              {package_details.map((p_ele, p_index) => (
                <div
                  className="send-div me-md-3"
                  onClick={() => {
                    setActivePackageItem(p_ele.name);
                  }}
                  style={{
                    backgroundColor:
                      active_package_item === p_ele.name
                        ? "#0057ff"
                        : "#F4F5FA",
                    color:
                      active_package_item === p_ele.name ? "#fff" : "#0c0c0c",
                  }}
                >
                  <img
                    src={AppConfig.CDN_Media_url + p_ele.img_file}
                    className="send-div-icon"
                    style={{
                      filter:
                        active_package_item === p_ele.name ? "invert(1)" : "",
                    }}
                  />
                  <div className="ms-1 fs-12px">{p_ele.name}</div>
                </div>
              ))}
            </div>
            {/* <div className="d-flex align-items-center">
           <div className="send-div me-md-3">
             <img
               src={
                 AppConfig.CDN_Media_url +
                 "95a35d96a54ae164d7dbfceb7382a511.svg"
               }
               className="send-div-icon"
             />
             <div className="ms-1 text-light-black fs-12px">Food</div>
           </div>

           <div className="send-div me-md-3">
             <img
               src={
                 AppConfig.CDN_Media_url +
                 "926efa8d5f8030a387e08ad62ae50944.svg"
               }
               className="send-div-icon"
             />
             <div className="ms-1 text-light-black fs-12px">Files</div>
           </div>

           <div className="send-div me-md-3">
             <img
               src={
                 AppConfig.CDN_Media_url +
                 "1260a96a4c2566c12889c057c6f7f0aa.svg"
               }
               className="send-div-icon"
             />
             <div className="ms-1 text-light-black fs-12px">Stationary</div>
           </div>

           <div className="send-div me-md-3">
             <img
               src={
                 AppConfig.CDN_Media_url +
                 "c068dd0e8c606491a5468d804cb072d3.svg"
               }
               className="send-div-icon"
             />
             <div className="ms-1 text-light-black fs-12px">Others</div>
           </div>
         </div> */}

            {active_package_item == "Others" ? (
              <div className="mt-2">
                <div className="fs-12px text-black-color pop-regular">
                  Others
                  <span className="text-prime1">*</span>
                </div>
                <div className="mt-2">
                  <input
                    type="text"
                    placeholder="Ex: Packaging Material"
                    className="package-input-style"
                  />
                </div>
              </div>
            ) : null}

            <div className="mt-2">
              <div className="fs-12px text-black-color pop-regular">
                Package Value
              </div>
              <div className="package-input-container mt-2">
                <div className="icon fs-12px DMSanst ">₹</div>
                <input
                  type="text"
                  placeholder="Ex: Packaging Material"
                  className="package-input-style"
                />
              </div>
            </div>

            <div className="mt-2">
              <div className="fs-12px text-black-color pop-regular">
                Attachment (Optional)
              </div>
              <div className="attach-add-div mt-2">
                {filePreview.length > 0
                  ? filePreview.map((previewUrl, index) => (
                      <div
                        className="position-relative me-3 d-flex"
                        key={index}
                      >
                        <img
                          src={previewUrl}
                          alt="File Preview"
                          // src={
                          //   AppConfig.CDN_Image_url +
                          //   "f512443df8aebc613c98f5acf3275cca.png"
                          // }
                          className=""
                        />
                        <div
                          className="close-icon fs-16px"
                          onClick={() => {
                            handleRemoveFile(index);
                          }}
                        >
                          ×
                        </div>
                      </div>
                    ))
                  : null}
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                  multiple
                />
                <div className="ms-auto attach-add" onClick={handleDivClick}>
                  +
                </div>
              </div>
            </div>

            <div className="mt-2">
              <div className="text-black-color pop-regular fs-12px mb-2">
                Payment Method
              </div>
              <div className="d-flex align-items-center">
                {payment_details.map((pay_ele, p_index) => (
                  <div
                    className="send-div me-md-3"
                    onClick={() => {
                      setActivePaymentItem(pay_ele.name);
                    }}
                    style={{
                      backgroundColor:
                        active_payment_item === pay_ele.name
                          ? "#0057ff"
                          : "#F4F5FA",
                      color:
                        active_payment_item === pay_ele.name
                          ? "#fff"
                          : "#0c0c0c",
                    }}
                  >
                    <img
                      src={pay_ele.img_file}
                      className="send-div-icon"
                      style={{
                        filter:
                          active_payment_item === pay_ele.name
                            ? "invert(1)"
                            : "",
                      }}
                    />
                    <div className="ms-1 fs-12px">{pay_ele.name}</div>
                  </div>
                ))}
              </div>
            </div>

            <div className="mt-2">
              <div className="fs-12px text-black-color pop-regular">
                COD Amount
              </div>
              <div className="package-input-container mt-2">
                <div className="icon fs-12px DMSanst ">₹</div>
                <input
                  type="text"
                  placeholder="Enter amount"
                  className="package-input-style"
                />
              </div>
            </div>

            <div className="primary-button center mt-3">Add package</div>
          </div>
        ) : 
        <div
        onClick={() => {
          SetPackageOpen({ is_open: true, value: "" });
        }}>
          <div className="primary-button center mt-3">Add Another Package</div>
        </div>
        }
      </div>

      <div className="bg-fff py-3 px-2 radius-6 shadow mb-3">
        <div className="fs-16px text-dark pop-font">Shipment Information</div>
        <div className="fs-11px text-light-gray pop-regular let_space_03 mt-1">
          Please enter details about shipments
        </div>

        {!address_selected.is_open ? (
          <div
            className="mt-2 border radius-6 p-2 d-flex cursor align-items-center"
            onClick={() => {
              SetAddressSelected({ is_open: true, value: "" });
            }}
          >
            <img
              src={
                AppConfig.CDN_Media_url + "3dfd4358f7e6ab7ac7c1e2fd4b852e1c.svg"
              }
              className="w-25px "
            />
            <div className="ps-2 text-dark uppercase fs-12px let_space_03">
              add / select Address
            </div>
          </div>
        ) : (
          <div className="mt-2 border radius-6 p-2 d-flex">
            <img
              src={
                AppConfig.CDN_Media_url + "3dfd4358f7e6ab7ac7c1e2fd4b852e1c.svg"
              }
              className="w-25px "
            />
            <div className="ms-2">
              <div className="fs-13px text-dark pop-font">Raju Textile</div>
              <div className="fs-10px text-gray pop-regular let_space_03">
                15, Nerhu Street, Pallipalayam, Namakkal - 652396
              </div>
            </div>
          </div>
        )}
        <div
          className="d-flex mt-2"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
          }}
        >
          <div
            className="w-10per"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "2px", // Thickness of the line
                borderLeft: "2px dashed #bbb",
                height: `${maxHeight}px`, // Set height dynamically
              }}
            ></div>
          </div>
          {/* <div className="w-10per vertical-dot-line" style={{height: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}></div> */}
          <div className="w-90per" ref={heightRefs}>
            {!contact_selected.is_open ? (
              <div
                className="shop-div d-flex align-items-center p-2 radius-6 mt-2"
                onClick={() => {
                  SetContactSelected({ is_open: true, value: "" });
                }}
              >
                <div className="w-30px bg-fff h-30px radius-6 center">
                  <img
                    src={
                      AppConfig.CDN_Image_url +
                      "b768514a88b9a901a98ea3bb85c71049.png"
                    }
                    className="w-10"
                  />
                </div>
                <div className="ps-2 text-dark uppercase fs-12px let_space_03">
                  add another contact
                </div>
              </div>
            ) : (
              <div className="shop-div d-flex align-items-center p-2 radius-6">
                <div className="w-30px bg-fff h-30px radius-6 center">
                  <img
                    src={
                      AppConfig.CDN_Image_url +
                      "b768514a88b9a901a98ea3bb85c71049.png"
                    }
                    className="w-10"
                  />
                </div>
                <div className="ps-2 d-flex">
                  <div className="">
                    <div className="os-sta ps-0 pop-regular let_space_03">
                      Contact
                    </div>
                    <div className="text-dark fs-12px pop-regular let_space_03">
                      Raju
                    </div>
                  </div>
                </div>
                <div className="ms-auto p-0 end">
                  <div className="os-icon shadow center">
                    <img
                      src={
                        AppConfig.CDN_Media_url +
                        "a78b858bd49bf56c9fa495b641269677.svg"
                      }
                      className="w-16px filter-invert"
                    />
                  </div>
                  <div className="ms-3 os-icon shadow center">
                    <img
                      src={
                        AppConfig.CDN_Media_url +
                        "ce8b7d884ffdb51228e7577cc36f38c0.svg"
                      }
                      className="w-16px filter-invert flip-icon"
                    />
                  </div>
                </div>
              </div>
            )}
            <div
              className="d-flex mt-2"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
              }}
            >
              <div
                className="w-10per"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: "2px", // Thickness of the line
                    borderLeft: "2px dashed #bbb",
                    height: `${maxHeight - 120}px`, // Set height dynamically
                  }}
                ></div>
              </div>
              {/* <div className="w-10per vertical-dot-line" style={{height: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}></div> */}
              <div className="w-90per" ref={heightRefs}>
                <div className="fs-11px text-light-dark pop-regular let_space_03 mt-2">
                  Package To Pickup
                </div>
                <div className="shop-div mt-2 p-2">
                  <div className="d-flex align-items-center pb-2">
                    <div className="w-30px bg-fff h-30px radius-6 center">
                      <div className="uppercase text-dark fs-13px ">p1</div>
                    </div>
                    <div className="ps-2 ">
                      <div className="os-sta ps-0 pop-regular let_space_03">
                        Content
                      </div>
                      <div className="text-dark fs-12px pop-regular let_space_03">
                        Notebook
                      </div>
                    </div>
                    <div className="ps-2">
                      <div className="os-sta ps-0 pop-regular let_space_03">
                        Value
                      </div>
                      <div className="text-dark fs-12px let_space_03">
                        <span className="icon fs-12px DMSanst ">₹</span>
                        <span className=" pop-regular">200</span>
                      </div>
                    </div>

                    <div className="ms-auto">
                      <img
                        src={
                          AppConfig.CDN_Media_url +
                          "f0e8463e68a70f6e4a7ae696c626f8aa.svg"
                        }
                        className="w-20px"
                      />
                    </div>
                  </div>
                  <div className="bg-fff p-2 radius-6">
                    <div className="fs-10px text-gray pop-regular">
                      Attachments
                    </div>
                    <div className="d-flex align-items-center my-2">
                      <div className="attch-img-div shadow me-2">
                        <img
                          src={
                            AppConfig.CDN_Image_url +
                            "f512443df8aebc613c98f5acf3275cca.png"
                          }
                        />
                      </div>
                      <div className="attch-img-div shadow me-2">
                        <img
                          src={
                            AppConfig.CDN_Image_url +
                            "d727592a461fce006f09ba169ce6751e.png"
                          }
                        />
                      </div>
                      <div className="attch-img-div shadow me-2">
                        <img
                          src={
                            AppConfig.CDN_Image_url +
                            "a2b7a893507c0e7a86e0de2c563d24fd.png"
                          }
                        />
                      </div>
                    </div>

                    <div className="border-top py-2 d-flex w-100">
                      <div className=" ">
                        <div className="fs-9px pb-1 pop-regular text-gray let_space_03">
                          Collect Payments
                        </div>
                        <div className="text-light-dark fs-11px let_space_03">
                          <span className="icon fs-11px DMSanst ">₹</span>
                          <span className=" pop-regular">2000</span>
                        </div>
                      </div>
                      <div className="ps-2">
                        <div className="fs-9px pb-1 pop-regular text-gray let_space_03">
                          Payment Method
                        </div>
                        <div className="bg-status bg-red uppercase fs-9px">
                          cod
                        </div>
                      </div>

                      <div className="ps-2 ">
                        <div className="fs-9px pb-1 pop-regular text-gray let_space_03">
                          Payment Mode
                        </div>
                        <div className="bg-status bg-prime1 capitalize fs-9px">
                          cash
                        </div>
                      </div>

                      <div className="ps-2 ">
                        <div className="fs-9px pb-1 pop-regular text-gray let_space_03">
                          Payment Status
                        </div>
                        <div className="bg-status bg-prime1 capitalize fs-9px">
                          not yet paid
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {create_package.is_open ?
                
                null
              :
              <div
              className="mt-2 p-2 border radius-6"
              onClick={() => {
                SetPackageSelectOpen({ is_open: true, value: "" });
              }}
            >
              <div className="d-flex align-items-center">
                <div className="w-30px bg-fff h-30px radius-6 center border">
                  <div className="uppercase text-dark fs-13px ">p</div>
                </div>
                <div className="ps-2 text-dark uppercase fs-12px let_space_03">
                  add / select package
                </div>
              </div>
            </div>
              }
              

                <div className="fs-11px text-light-dark pop-regular let_space_03 mt-2">
                  Package To Drop
                </div>
                <div className="mt-2 p-2 border radius-6">
                  <div className="d-flex align-items-center">
                    <div className="w-30px bg-fff h-30px radius-6 center border">
                      <div className="uppercase text-dark fs-13px ">p</div>
                    </div>
                    <div className="ps-2 text-dark uppercase fs-12px let_space_03">
                      select package
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="shop-div d-flex align-items-center p-2 radius-6 mt-2">
              <div className="w-30px bg-fff h-30px radius-6 center">
                <img
                  src={
                    AppConfig.CDN_Image_url +
                    "b768514a88b9a901a98ea3bb85c71049.png"
                  }
                  className="w-10"
                />
              </div>
              <div className="ps-2 text-dark uppercase fs-12px let_space_03">
                add another contact
              </div>
            </div>
          </div>
        </div>

        <div className="mt-2 border radius-6 p-2 d-flex">
          <img
            src={
              AppConfig.CDN_Media_url + "3dfd4358f7e6ab7ac7c1e2fd4b852e1c.svg"
            }
            className="w-25px "
          />
          <div className="ms-2">
            <div className="fs-13px text-dark pop-font">Home</div>
            <div className="fs-10px text-gray pop-regular let_space_03">
              152, Main complex, Market street, Erode - 6358210
            </div>
          </div>
        </div>
        <div
          className="d-flex mt-2"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
          }}
        >
          <div
            className="w-10per"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "2px", // Thickness of the line
                borderLeft: "2px dashed #bbb",
                height: `${maxHeight}px`, // Set height dynamically
              }}
            ></div>
          </div>
          {/* <div className="w-10per vertical-dot-line" style={{height: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}></div> */}
          <div className="w-90per" ref={heightRefs}>
            <div className="shop-div d-flex align-items-center p-2 radius-6">
              <div className="w-30px bg-fff h-30px radius-6 center">
                <img
                  src={
                    AppConfig.CDN_Image_url +
                    "b768514a88b9a901a98ea3bb85c71049.png"
                  }
                  className="w-10"
                />
              </div>
              <div className="ps-2 d-flex">
                <div className="">
                  <div className="os-sta ps-0 pop-regular let_space_03">
                    Contact
                  </div>
                  <div className="text-dark fs-12px pop-regular let_space_03">
                    Govind
                  </div>
                </div>
              </div>
              <div className="ms-auto p-0 end">
                <div className="os-icon shadow center">
                  <img
                    src={
                      AppConfig.CDN_Media_url +
                      "a78b858bd49bf56c9fa495b641269677.svg"
                    }
                    className="w-16px filter-invert"
                  />
                </div>
                <div className="ms-3 os-icon shadow center">
                  <img
                    src={
                      AppConfig.CDN_Media_url +
                      "ce8b7d884ffdb51228e7577cc36f38c0.svg"
                    }
                    className="w-16px filter-invert flip-icon"
                  />
                </div>
              </div>
            </div>
            <div
              className="d-flex mt-2"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
              }}
            >
              <div
                className="w-10per"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: "2px", // Thickness of the line
                    borderLeft: "2px dashed #bbb",
                    height: `${maxHeight - 120}px`, // Set height dynamically
                  }}
                ></div>
              </div>
              {/* <div className="w-10per vertical-dot-line" style={{height: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}></div> */}
              <div className="w-90per" ref={heightRefs}>
                <div className="fs-11px text-light-dark pop-regular let_space_03 mt-2">
                  Package To Pickup
                </div>
                <div className="shop-div mt-2 p-2">
                  <div className="d-flex align-items-center pb-2">
                    <div className="w-30px bg-fff h-30px radius-6 center">
                      <div className="uppercase text-dark fs-13px ">p2</div>
                    </div>
                    <div className="ps-2 ">
                      <div className="os-sta ps-0 pop-regular let_space_03">
                        Content
                      </div>
                      <div className="text-dark fs-12px pop-regular let_space_03">
                        Notebook
                      </div>
                    </div>
                    <div className="ps-2">
                      <div className="os-sta ps-0 pop-regular let_space_03">
                        Value
                      </div>
                      <div className="text-dark fs-12px let_space_03">
                        <span className="icon fs-12px DMSanst ">₹</span>
                        <span className=" pop-regular">200</span>
                      </div>
                    </div>

                    <div className="ms-auto">
                      <img
                        src={
                          AppConfig.CDN_Media_url +
                          "f0e8463e68a70f6e4a7ae696c626f8aa.svg"
                        }
                        className="w-20px"
                      />
                    </div>
                  </div>
                  <div className="bg-fff p-2 radius-6">
                    <div className="fs-10px text-gray pop-regular">
                      Attachments
                    </div>
                    <div className="d-flex align-items-center my-2">
                      <div className="attch-img-div shadow me-2">
                        <img
                          src={
                            AppConfig.CDN_Image_url +
                            "f512443df8aebc613c98f5acf3275cca.png"
                          }
                        />
                      </div>
                      <div className="attch-img-div shadow me-2">
                        <img
                          src={
                            AppConfig.CDN_Image_url +
                            "d727592a461fce006f09ba169ce6751e.png"
                          }
                        />
                      </div>
                      <div className="attch-img-div shadow me-2">
                        <img
                          src={
                            AppConfig.CDN_Image_url +
                            "a2b7a893507c0e7a86e0de2c563d24fd.png"
                          }
                        />
                      </div>
                    </div>

                    <div className="border-top py-2 d-flex w-100">
                      <div className=" ">
                        <div className="fs-9px pb-1 pop-regular text-gray let_space_03">
                          Collect Payments
                        </div>
                        <div className="text-light-dark fs-11px let_space_03">
                          <span className="icon fs-11px DMSanst ">₹</span>
                          <span className=" pop-regular">2000</span>
                        </div>
                      </div>
                      <div className="ps-2">
                        <div className="fs-9px pb-1 pop-regular text-gray let_space_03">
                          Payment Method
                        </div>
                        <div className="bg-status bg-red uppercase fs-9px">
                          cod
                        </div>
                      </div>

                      <div className="ps-2 ">
                        <div className="fs-9px pb-1 pop-regular text-gray let_space_03">
                          Payment Mode
                        </div>
                        <div className="bg-status bg-prime1 capitalize fs-9px">
                          cash
                        </div>
                      </div>

                      <div className="ps-2 ">
                        <div className="fs-9px pb-1 pop-regular text-gray let_space_03">
                          Payment Status
                        </div>
                        <div className="bg-status bg-prime1 capitalize fs-9px">
                          not yet paid
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-2 p-2 border radius-6">
                  <div className="d-flex align-items-center">
                    <div className="w-30px bg-fff h-30px radius-6 center border">
                      <div className="uppercase text-dark fs-13px ">p</div>
                    </div>
                    <div className="ps-2 text-dark uppercase fs-12px let_space_03">
                      add / select package
                    </div>
                  </div>
                </div>

                <div className="fs-11px text-light-dark pop-regular let_space_03 mt-2">
                  Package To Drop
                </div>
                <div className="shop-div mt-2 p-2">
                  <div className="d-flex align-items-center pb-2">
                    <div className="w-30px bg-fff h-30px radius-6 center">
                      <div className="uppercase text-dark fs-13px ">p1</div>
                    </div>
                    <div className="ps-2 ">
                      <div className="os-sta ps-0 pop-regular let_space_03">
                        Content
                      </div>
                      <div className="text-dark fs-12px pop-regular let_space_03">
                        Notebook
                      </div>
                    </div>
                    <div className="ps-2">
                      <div className="os-sta ps-0 pop-regular let_space_03">
                        Value
                      </div>
                      <div className="text-dark fs-12px let_space_03">
                        <span className="icon fs-12px DMSanst ">₹</span>
                        <span className=" pop-regular">200</span>
                      </div>
                    </div>

                    <div className="ms-auto">
                      <img
                        src={
                          AppConfig.CDN_Media_url +
                          "f0e8463e68a70f6e4a7ae696c626f8aa.svg"
                        }
                        className="w-20px"
                      />
                    </div>
                  </div>
                  <div className="bg-fff p-2 radius-6">
                    <div className="fs-10px text-gray pop-regular">
                      Attachments
                    </div>
                    <div className="d-flex align-items-center my-2">
                      <div className="attch-img-div shadow me-2">
                        <img
                          src={
                            AppConfig.CDN_Image_url +
                            "f512443df8aebc613c98f5acf3275cca.png"
                          }
                        />
                      </div>
                      <div className="attch-img-div shadow me-2">
                        <img
                          src={
                            AppConfig.CDN_Image_url +
                            "d727592a461fce006f09ba169ce6751e.png"
                          }
                        />
                      </div>
                      <div className="attch-img-div shadow me-2">
                        <img
                          src={
                            AppConfig.CDN_Image_url +
                            "a2b7a893507c0e7a86e0de2c563d24fd.png"
                          }
                        />
                      </div>
                    </div>

                    <div className="border-top py-2 d-flex w-100">
                      <div className=" ">
                        <div className="fs-9px pb-1 pop-regular text-gray let_space_03">
                          Collect Payments
                        </div>
                        <div className="text-light-dark fs-11px let_space_03">
                          <span className="icon fs-11px DMSanst ">₹</span>
                          <span className=" pop-regular">2000</span>
                        </div>
                      </div>
                      <div className="ps-2">
                        <div className="fs-9px pb-1 pop-regular text-gray let_space_03">
                          Payment Method
                        </div>
                        <div className="bg-status bg-red uppercase fs-9px">
                          cod
                        </div>
                      </div>

                      <div className="ps-2 ">
                        <div className="fs-9px pb-1 pop-regular text-gray let_space_03">
                          Payment Mode
                        </div>
                        <div className="bg-status bg-prime1 capitalize fs-9px">
                          cash
                        </div>
                      </div>

                      <div className="ps-2 ">
                        <div className="fs-9px pb-1 pop-regular text-gray let_space_03">
                          Payment Status
                        </div>
                        <div className="bg-status bg-prime1 capitalize fs-9px">
                          not yet paid
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-2 p-2 border radius-6">
                  <div className="d-flex align-items-center">
                    <div className="w-30px bg-fff h-30px radius-6 center border">
                      <div className="uppercase text-dark fs-13px ">p</div>
                    </div>
                    <div className="ps-2 text-dark uppercase fs-12px let_space_03">
                      add / select package
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="shop-div d-flex align-items-center p-2 radius-6 mt-2">
              <div className="w-30px bg-fff h-30px radius-6 center">
                <img
                  src={
                    AppConfig.CDN_Image_url +
                    "b768514a88b9a901a98ea3bb85c71049.png"
                  }
                  className="w-10"
                />
              </div>
              <div className="ps-2 text-dark uppercase fs-12px let_space_03">
                add another contact
              </div>
            </div>
          </div>
        </div>
        <button className="primary-button my-3">Confirm order</button>
      </div>

      <SwipeableBottomSheet
        overflowHeight={0}
        style={{ zIndex: 1300 }}
        open={contact_selected.is_open}
        onClose={() => {
          SetContactSelected({ is_open: false, value: "" });
        }}
        onChange={() => {
          SetContactSelected({ is_open: false, value: "" });
        }}
      >
        <div
          className="bg-fff radius-top p-2"
          // style={{ minHeight: "500px", maxHeight: "auto" }}
        >
          <div className="text-center py-2">
            <div className="fs-18px text-dark fw-bold pop-semi-bold">
              Add contact details for this location{" "}
            </div>
            <div className="fs-12px text-gray pop-regular">
              This phone number allows us to coordinate directly with the
              receiver
            </div>
            {/* <div className="ms-auto" 
            onClick={()=>{
              SetContactSelected({is_open: false, value: ""});
            }}>
                      <img
                        src={
                          AppConfig.CDN_Media_url +
                          "f0e8463e68a70f6e4a7ae696c626f8aa.svg"
                        }
                        className="w-20px"
                      />
                    </div> */}

            <div className="py-3">
              <div className="position-relative mt-4">
                <input
                  type="text"
                  className="outside"
                  name="other_value"
                  // value={add_address.other_type_value}
                  // onChange={(event) => {
                  //   SetAddAddress((prevalue: any) => {
                  //     prevalue.other_type_value = event.target.value;
                  //     return { ...prevalue };
                  //   });
                  // validate_add_address();
                  // }}
                />
                {/* {errors.other_value ? (
                    <span className="floating-label-outside text-red">
                      Location Name Required *
                    </span>
                  ) : ( */}
                <span className="floating-label-outside">
                  Contact Person Name *
                </span>
                {/* )} */}
              </div>

              <div className="position-relative mt-4">
                <input
                  type="number"
                  className="outside"
                  name="other_value"
                  // value={add_address.other_type_value}
                  // onChange={(event) => {
                  //   SetAddAddress((prevalue: any) => {
                  //     prevalue.other_type_value = event.target.value;
                  //     return { ...prevalue };
                  //   });
                  // validate_add_address();
                  // }}
                />
                {/* {errors.other_value ? (
                    <span className="floating-label-outside text-red">
                      Location Name Required *
                    </span>
                  ) : ( */}
                <span className="floating-label-outside">Phone Number *</span>
                {/* )} */}
              </div>

              <button className="primary-button my-3">
                Save contact details
              </button>
            </div>
          </div>
        </div>
      </SwipeableBottomSheet>

      <SwipeableBottomSheet
        overflowHeight={0}
        style={{ zIndex: 1300 }}
        open={package_select_open.is_open}
        onClose={() => {
          SetPackageSelectOpen({ is_open: false, value: "" });
        }}
        onChange={() => {
          SetPackageSelectOpen({ is_open: false, value: "" });
        }}
      >
        <div className="bg-fff radius-top p-2">
{create_package.is_open ? 
  <div className="mt-2">
                <div className="mb-3 d-flex ">
                  <div>
                  <div className="fs-16px text-dark pop-font let_space_03">
                    Package 2
                  </div>
                  <div className="text-gray fs-10px pop-regular let_space_03 mt-1">
                    Add details about this package
                  </div>
                  </div>
                  <div className="ms-auto cursor p-2 "
                    onClick={()=>{
                      SetCreatePackage({is_open:false, value:""});
                    }}
                  >
                    <img src={AppConfig.CDN_Media_url+"f0e8463e68a70f6e4a7ae696c626f8aa.svg"} className="w-20px" />
                  </div>
                  
                </div>
                <div className="text-black-color pop-regular fs-12px mb-2">
                  What are you sending?
                </div>
                <div className="d-flex align-items-center">
                  {package_details.map((p_ele, p_index) => (
                    <div
                      className="send-div me-md-3"
                      onClick={() => {
                        setActivePackageItem(p_ele.name);
                      }}
                      style={{
                        backgroundColor:
                          active_package_item === p_ele.name
                            ? "#0057ff"
                            : "#F4F5FA",
                        color:
                          active_package_item === p_ele.name ? "#fff" : "#0c0c0c",
                      }}
                    >
                      <img
                        src={AppConfig.CDN_Media_url + p_ele.img_file}
                        className="send-div-icon"
                        style={{
                          filter:
                            active_package_item === p_ele.name ? "invert(1)" : "",
                        }}
                      />
                      <div className="ms-1 fs-12px">{p_ele.name}</div>
                    </div>
                  ))}
                </div>
                {/* <div className="d-flex align-items-center">
               <div className="send-div me-md-3">
                 <img
                   src={
                     AppConfig.CDN_Media_url +
                     "95a35d96a54ae164d7dbfceb7382a511.svg"
                   }
                   className="send-div-icon"
                 />
                 <div className="ms-1 text-light-black fs-12px">Food</div>
               </div>
    
               <div className="send-div me-md-3">
                 <img
                   src={
                     AppConfig.CDN_Media_url +
                     "926efa8d5f8030a387e08ad62ae50944.svg"
                   }
                   className="send-div-icon"
                 />
                 <div className="ms-1 text-light-black fs-12px">Files</div>
               </div>
    
               <div className="send-div me-md-3">
                 <img
                   src={
                     AppConfig.CDN_Media_url +
                     "1260a96a4c2566c12889c057c6f7f0aa.svg"
                   }
                   className="send-div-icon"
                 />
                 <div className="ms-1 text-light-black fs-12px">Stationary</div>
               </div>
    
               <div className="send-div me-md-3">
                 <img
                   src={
                     AppConfig.CDN_Media_url +
                     "c068dd0e8c606491a5468d804cb072d3.svg"
                   }
                   className="send-div-icon"
                 />
                 <div className="ms-1 text-light-black fs-12px">Others</div>
               </div>
             </div> */}
    
                {active_package_item == "Others" ? (
                  <div className="mt-2">
                    <div className="fs-12px text-black-color pop-regular">
                      Others
                      <span className="text-prime1">*</span>
                    </div>
                    <div className="mt-2">
                      <input
                        type="text"
                        placeholder="Ex: Packaging Material"
                        className="package-input-style"
                      />
                    </div>
                  </div>
                ) : null}
    
                <div className="mt-2">
                  <div className="fs-12px text-black-color pop-regular">
                    Package Value
                  </div>
                  <div className="package-input-container mt-2">
                    <div className="icon fs-12px DMSanst ">₹</div>
                    <input
                      type="text"
                      placeholder="Ex: Packaging Material"
                      className="package-input-style"
                    />
                  </div>
                </div>
    
                <div className="mt-2">
                  <div className="fs-12px text-black-color pop-regular">
                    Attachment (Optional)
                  </div>
                  <div className="attach-add-div mt-2">
                    {filePreview.length > 0
                      ? filePreview.map((previewUrl, index) => (
                          <div
                            className="position-relative me-3 d-flex"
                            key={index}
                          >
                            <img
                              src={previewUrl}
                              alt="File Preview"
                              // src={
                              //   AppConfig.CDN_Image_url +
                              //   "f512443df8aebc613c98f5acf3275cca.png"
                              // }
                              className=""
                            />
                            <div
                              className="close-icon fs-16px"
                              onClick={() => {
                                handleRemoveFile(index);
                              }}
                            >
                              ×
                            </div>
                          </div>
                        ))
                      : null}
                    <input
                      type="file"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                      multiple
                    />
                    <div className="ms-auto attach-add" onClick={handleDivClick}>
                      +
                    </div>
                  </div>
                </div>
    
                <div className="mt-2">
                  <div className="text-black-color pop-regular fs-12px mb-2">
                    Payment Method
                  </div>
                  <div className="d-flex align-items-center">
                    {payment_details.map((pay_ele, p_index) => (
                      <div
                        className="send-div me-md-3"
                        onClick={() => {
                          setActivePaymentItem(pay_ele.name);
                        }}
                        style={{
                          backgroundColor:
                            active_payment_item === pay_ele.name
                              ? "#0057ff"
                              : "#F4F5FA",
                          color:
                            active_payment_item === pay_ele.name
                              ? "#fff"
                              : "#0c0c0c",
                        }}
                      >
                        <img
                          src={pay_ele.img_file}
                          className="send-div-icon"
                          style={{
                            filter:
                              active_payment_item === pay_ele.name
                                ? "invert(1)"
                                : "",
                          }}
                        />
                        <div className="ms-1 fs-12px">{pay_ele.name}</div>
                      </div>
                    ))}
                  </div>
                </div>
    
                <div className="mt-2">
                  <div className="fs-12px text-black-color pop-regular">
                    COD Amount
                  </div>
                  <div className="package-input-container mt-2">
                    <div className="icon fs-12px DMSanst ">₹</div>
                    <input
                      type="text"
                      placeholder="Enter amount"
                      className="package-input-style"
                    />
                  </div>
                </div>
    
                <div className="primary-button center mt-3 mb-5">Add package</div>
              </div>
              :
              <div
              className=""
              // style={{ minHeight: "500px", maxHeight: "auto" }}
            >
              <div className="d-flex align-items-center p-2">
                <div className="fs-18px text-dark fw-bold pop-semi-bold">
                  Select Package
                </div>
                <div
                  className="ms-auto cursor"
                  onClick={() => {
                    SetPackageSelectOpen({ is_open: false, value: "" });
                  }}
                >
                  <img
                    src={
                      AppConfig.CDN_Media_url +
                      "f0e8463e68a70f6e4a7ae696c626f8aa.svg"
                    }
                    className="w-20px"
                  />
              </div>
              </div>
    
                <div className="pt-3 pb-4">
                  <div className="shop-div mb-2 p-2">
                    <div className="d-flex align-items-center pb-2">
                      <div className="w-30px bg-fff h-30px radius-6 center">
                        <div className="uppercase text-dark fs-13px p-2">p1</div>
                      </div>
                      <div className="ps-2 ">
                        <div className="d-flex align-items-center">
                          <div>
                            <div className="os-sta ps-0 pop-regular let_space_03">
                              Content
                            </div>
                            <div className="text-dark fs-12px pop-regular let_space_03">
                              Notebook
                            </div>
                          </div>
                          <div className="ps-2">
                            <div className="os-sta ps-0 pop-regular let_space_03">
                              Value
                            </div>
                            <div className="text-dark fs-12px let_space_03">
                              <span className="icon fs-12px DMSanst ">₹</span>
                              <span className=" pop-regular">200</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
    
                    
                    <div className="border-top py-2 d-flex w-100">
                          <div className=" ">
                            <div className="fs-9px pb-1 pop-regular text-gray let_space_03">
                              Collect Payments
                            </div>
                            <div className="text-light-dark fs-11px let_space_03">
                              <span className="icon fs-11px DMSanst ">₹</span>
                              <span className=" pop-regular">2000</span>
                            </div>
                          </div>
                          <div className="ps-2">
                            <div className="fs-9px pb-1 pop-regular text-gray let_space_03">
                              Payment Method
                            </div>
                            <div className="bg-status bg-red uppercase fs-9px">
                              cod
                            </div>
                          </div>
    
                          <div className="ps-2 ">
                            <div className="fs-9px pb-1 pop-regular text-gray let_space_03">
                              Payment Mode
                            </div>
                            <div className="bg-status bg-prime1 capitalize fs-9px">
                              cash
                            </div>
                          </div>
    
                          <div className="ps-2 ">
                            <div className="fs-9px pb-1 pop-regular text-gray let_space_03">
                              Payment Status
                            </div>
                            <div className="bg-status bg-prime1 capitalize fs-9px">
                              not yet paid
                            </div>
                          </div>
                        </div>
                  </div>
    
                  <div className="border radius-6 bg-fff mb-2 p-2">
                    <div className="d-flex align-items-center pb-2">
                      <div className="w-30px bg-fff h-30px radius-6 center">
                        <div className="uppercase text-dark fs-13px p-2">p1</div>
                      </div>
                      <div className="ps-2 ">
                        <div className="d-flex align-items-center">
                          <div>
                            <div className="os-sta ps-0 pop-regular let_space_03">
                              Content
                            </div>
                            <div className="text-dark fs-12px pop-regular let_space_03">
                              Notebook
                            </div>
                          </div>
                          <div className="ps-2">
                            <div className="os-sta ps-0 pop-regular let_space_03">
                              Value
                            </div>
                            <div className="text-dark fs-12px let_space_03">
                              <span className="icon fs-12px DMSanst ">₹</span>
                              <span className=" pop-regular">200</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
    
                    
                    <div className="border-top py-2 d-flex w-100">
                          <div className=" ">
                            <div className="fs-9px pb-1 pop-regular text-gray let_space_03">
                              Collect Payments
                            </div>
                            <div className="text-light-dark fs-11px let_space_03">
                              <span className="icon fs-11px DMSanst ">₹</span>
                              <span className=" pop-regular">2000</span>
                            </div>
                          </div>
                          <div className="ps-2">
                            <div className="fs-9px pb-1 pop-regular text-gray let_space_03">
                              Payment Method
                            </div>
                            <div className="bg-status bg-red uppercase fs-9px">
                              cod
                            </div>
                          </div>
    
                          <div className="ps-2 ">
                            <div className="fs-9px pb-1 pop-regular text-gray let_space_03">
                              Payment Mode
                            </div>
                            <div className="bg-status bg-prime1 capitalize fs-9px">
                              cash
                            </div>
                          </div>
    
                          <div className="ps-2 ">
                            <div className="fs-9px pb-1 pop-regular text-gray let_space_03">
                              Payment Status
                            </div>
                            <div className="bg-status bg-prime1 capitalize fs-9px">
                              not yet paid
                            </div>
                          </div>
                        </div>
                  </div>
    
                  <button className="primary-button my-3"
                  onClick={()=>{
                    SetCreatePackage({is_open:true, value:""});
                  }}>Create Package</button>
                </div>
            </div>
}


        </div>
       
      </SwipeableBottomSheet>

      {/* <div className="pt-3 pb-2">
        <div className="fs-9px pop-regular text-center text-gray let_space_03">
          By continuing, you agree to our{" "}
          <span className="text-primary">T&C</span> and there are no{" "}
          <span className="text-primary">restricted items</span> in the package.
          SuperStore shall not be liable for any theft/loss/misplacement/damages
          to the items in any manner whatsover.
        </div>
      </div> */}
    </div>
  );
}
