import React, {
  Component,
  useEffect,
  useContext,
  useState,
  useRef,
} from "react";
import {
  Link,
  useParams,
  useSearchParams,
  useOutletContext,
  useNavigate,
} from "react-router-dom";
import { AppConfig } from "../../../config";
import DataContext from "../../../utils/Context/DataContext";

export default function NewSearch(props: any) {
  const navigate = useNavigate();
  const context: any = useContext(DataContext);
  const [no_search, SetNoSearch] = useState(false);
  const [no_result, SetNoResult] = useState(false);

  return (
    <div className="custom-container p-3">
      <div className="address-search-bar d-flex align-items-center mb-3 w-100">
        <div className="w-10per">
        <img
          src={AppConfig.CDN_Media_url + "4075afa7aafecff54a5220213ad396f0.svg"}
          className="w-20px"
        />
        </div>
        <div className="ps-2 w-80per">
          <input
            type="text"
            className="let_space_03 custom-input-text"
            placeholder="Search for foods or restaurant"
          />
        </div>
        <div className="ms-auto cursor ps-2 w-10per">
          <img
            src={
              AppConfig.CDN_Media_url + "d6da0c9c3517352622470b635f8a8d38.svg"
            }
            className="w-20px"
          />
        </div>
      </div>

      {no_search ? (
        <div className="text-center px-3 py-5">
          <img
            src={
              AppConfig.CDN_Image_url + "10b4a6f83b5ebd425df8e4230ea043f1.png"
            }
            className="w-100px"
          />
          <div className="address-sub-text let_space_03 mt-2">
            Search for Delicious Food
          </div>
        </div>
      ) : (
        no_result ? (
          <div className="text-center px-3 py-5">
            <img
              src={
                AppConfig.CDN_Image_url + "10b4a6f83b5ebd425df8e4230ea043f1.png"
              }
              className="w-100px"
            />
            <div className="address-sub-text let_space_03 mt-2">
              Search Not Found
            </div>
          </div>
        ) :
        <div>
          <div className="address-sub-text let_space_03">
            Relevant Search Result for
            <span className="text-black-color">“Briyani”</span>
          </div>
          <div className="my-3">
            <div className="bg-fff py-3 px-2 radius-10 d-flex align-items-center" 
            onClick={()=>{
              navigate("/SearchResult");
            }}>
              <img
                src={
                  AppConfig.CDN_Image_url +
                  "d11bb99ad9714b9425eb9dcf938eb240.jpg"
                }
                className="search-dish-img me-2"
              />
              <div>
                <div className="loc-head ">Briyani</div>
                <div className="address-sub-text let_space_03">Dish</div>
              </div>
            </div>
          </div>

          <div className="address-sub-text let_space_03">
            Relevant Restaurant
          </div>
          <div className="my-3 bg-fff py-3 px-2 ">
            <div className="border-bottom pb-2 mb-2 radius-10 d-flex align-items-center ">
              <img
                src={
                  AppConfig.CDN_Image_url +
                  "d11bb99ad9714b9425eb9dcf938eb240.jpg"
                }
                className="search-restaurant-img me-2"
              />
              <div>
                <div className="loc-head ">Briyani Stall</div>
                <div className="d-flex align-items-center">
                  <img
                    src={
                      AppConfig.CDN_Media_url +
                      "24304beb136d9e972875d8558aa3484f.svg"
                    }
                    className="img-fluid pe-1"
                    width={15}
                  />
                  <div className="address-sub-text let_space_03">
                    30-40 mins
                  </div>
                  <div className="mx-2 text-gray">•</div>
                  <img
                    src={
                      AppConfig.CDN_Media_url +
                      "68ea449942e40acb8d82122bf48d1003.svg"
                    }
                    className="img-fluid pe-1 "
                    width={15}
                  />
                  <div className="address-sub-text let_space_03">
                    Erode Brough Road
                  </div>
                </div>
              </div>
            </div>

            <div className="border-bottom pb-2 mb-2 radius-10 d-flex align-items-center ">
              <img
                src={
                  AppConfig.CDN_Image_url +
                  "d11bb99ad9714b9425eb9dcf938eb240.jpg"
                }
                className="search-restaurant-img me-2"
              />
              <div>
                <div className="loc-head ">Briyani Pot</div>
                <div className="d-flex align-items-center">
                  <img
                    src={
                      AppConfig.CDN_Media_url +
                      "24304beb136d9e972875d8558aa3484f.svg"
                    }
                    className="img-fluid pe-1"
                    width={15}
                  />
                  <div className="address-sub-text let_space_03">
                    30-40 mins
                  </div>
                  <div className="mx-2 text-gray">•</div>
                  <img
                    src={
                      AppConfig.CDN_Media_url +
                      "68ea449942e40acb8d82122bf48d1003.svg"
                    }
                    className="img-fluid pe-1 "
                    width={15}
                  />
                  <div className="address-sub-text let_space_03">
                    Erode Brough Road
                  </div>
                </div>
              </div>
            </div>

            <div className="border-bottom pb-2 mb-2 radius-10 d-flex align-items-center ">
              <img
                src={
                  AppConfig.CDN_Image_url +
                  "d11bb99ad9714b9425eb9dcf938eb240.jpg"
                }
                className="search-restaurant-img me-2"
              />
              <div>
                <div className="loc-head ">Briyani Center</div>
                <div className="d-flex align-items-center">
                  <img
                    src={
                      AppConfig.CDN_Media_url +
                      "24304beb136d9e972875d8558aa3484f.svg"
                    }
                    className="img-fluid pe-1"
                    width={15}
                  />
                  <div className="address-sub-text let_space_03 fs-10px">
                    30-40 mins
                  </div>
                  <div className="mx-2 text-gray">•</div>
                  <img
                    src={
                      AppConfig.CDN_Media_url +
                      "68ea449942e40acb8d82122bf48d1003.svg"
                    }
                    className="img-fluid pe-1 "
                    width={15}
                  />
                  <div className="address-sub-text let_space_03 fs-10px">
                    Erode Brough Road
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="address-sub-text let_space_03">More Items</div>
          <div className="my-3 bg-fff py-3 px-2 ">
            <div className="border-bottom pb-2 mb-2 radius-10 d-flex align-items-center ">
              <img
                src={
                  AppConfig.CDN_Image_url +
                  "d11bb99ad9714b9425eb9dcf938eb240.jpg"
                }
                className="search-restaurant-img me-2"
              />
              <div>
                <div className="loc-head ">Veg riyani</div>
                <div className="address-sub-text let_space_03">Dish</div>
              </div>
            </div>

            <div className="border-bottom pb-2 mb-2 radius-10 d-flex align-items-center ">
              <img
                src={
                  AppConfig.CDN_Image_url +
                  "d11bb99ad9714b9425eb9dcf938eb240.jpg"
                }
                className="search-restaurant-img me-2"
              />
              <div>
                <div className="loc-head ">Chicken Briyani</div>
                <div className="address-sub-text let_space_03">Dish</div>
              </div>
            </div>

            <div className="border-bottom pb-2 mb-2 radius-10 d-flex align-items-center ">
              <img
                src={
                  AppConfig.CDN_Image_url +
                  "d11bb99ad9714b9425eb9dcf938eb240.jpg"
                }
                className="search-restaurant-img me-2"
              />
              <div>
                <div className="loc-head ">Mutton Briyani</div>
                <div className="address-sub-text let_space_03">Dish</div>
              </div>
            </div>
          </div>
        </div>
      )}
     
    </div>
  );
}
