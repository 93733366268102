import React, { useState, useContext } from "react";
import { AppConfig } from "../../config";

function QuantityButtonComponent(g_props: any) {
  const Spinner = () => <div className="vendor-list-loader-spinner"></div>;

  return (
    <>
      {g_props.from_page == "vendor" ? (
        <>
          {g_props.context_data.app_data.selected_section.sector == "2" ? (
            <>
              <div className="flex offer mb-2 center p-2">
                {g_props.api_loading ? (
                  <Spinner />
                ) : (
                  <>
                    {/* - */}
                    <div
                      className="cursor pr-1 w-25 center"
                      onClick={() => {
                        g_props.minus();
                      }}
                    >
                      <img
                        src={
                          AppConfig.CDN_Media_url +
                          "b48a5c15d42fc79330a9658ff3779170.svg"
                        }
                        className="type-img"
                      />
                    </div>
                    {/* qty */}
                    <div className="w-50 center">
                      <p className="smooth">
                        <b>{g_props.addcount}</b>
                      </p>
                    </div>
                    {/* + */}
                    <div
                      className="cursor pl-1 w-25"
                      onClick={() => {
                        g_props.pluse();
                      }}
                    >
                      <img
                        src={
                          AppConfig.CDN_Media_url +
                          "2a39073bf6a8f72e9d703ad4b9cdeab4.svg"
                        }
                        className="type-img"
                      />
                    </div>
                  </>
                )}
              </div>
            </>
          ) : null}

          {g_props.context_data.app_data.selected_section.sector == "1" ||
          g_props.context_data.app_data.selected_section.sector == "3" ? (
            <>
              <div className="d-flex center">
                {g_props?.api_loading ? (
                  <Spinner />
                ) : (
                  <>
                    {/* - */}
                    <div
                      className="cursor pr-1 center"
                      onClick={() => {
                        g_props?.minus();
                      }}
                    >
                      <img
                        src={
                          AppConfig.CDN_Media_url +
                          "b48a5c15d42fc79330a9658ff3779170.svg"
                        }
                        className="type-img"
                      />
                    </div>
                    {/* qty */}
                    <div className="w-100 center">
                      <p className="smooth">
                        <b>{g_props?.addcount}</b>
                      </p>
                    </div>
                    {/* + */}
                    <div
                      className="cursor pl-1"
                      onClick={() => {
                        g_props?.pluse();
                      }}
                    >
                      <img
                        src={
                          AppConfig.CDN_Media_url +
                          "2a39073bf6a8f72e9d703ad4b9cdeab4.svg"
                        }
                        className="type-img"
                      />
                    </div>
                  </>
                )}
              </div>
            </>
          ) : null}
        </>
      ) : null}

      {g_props.from_page == "cart" ? (
        <>
          <div className="d-flex center">
            <>
              {/* - */}
              <div
                className="cursor pe-2 w-25 center"
                onClick={() => {
                  if (!g_props.api_loading) {
                    g_props?.minus();
                  }
                }}
              >
                {/* {!g_props.api_loading ? ( */}
                <img
                  src={
                    AppConfig.CDN_Media_url +
                    "1b669eb5a49a8f4778ec18b862af8324.svg"
                  }
                  className="type-img"
                />
                {/* ) : null} */}
              </div>
              {/* quantity */}
              <div className="w-50 center">
                {g_props.api_loading ? (
                  <Spinner />
                ) : (
                  <p className="text-green-0 small">
                    <b>{g_props.addcount}</b>
                  </p>
                )}
              </div>
              {/* + */}
              <div
                className="cursor px-2 w-25"
                onClick={() => {
                  if (!g_props.api_loading) {
                    g_props?.pluse();
                  }
                }}
              >
                {/* {!g_props.api_loading ? ( */}
                <img
                  src={
                    AppConfig.CDN_Media_url +
                    "ed4c4c5f46f208f8238c965eaaa7c21f.svg"
                  }
                  className="type-img"
                />
                {/* ) : null} */}
              </div>
            </>
          </div>
        </>
      ) : null}
    </>
  );
}

export default QuantityButtonComponent;
