import {useState, useEffect} from 'react';

import SendbirdChat from '@sendbird/chat'
import {GroupChannel} from '@sendbird/uikit-react/GroupChannel';
import SendbirdProvider from '@sendbird/uikit-react/SendbirdProvider';
import { GroupChannelModule, SendbirdGroupChat } from '@sendbird/chat/groupChannel';
import '@sendbird/uikit-react/dist/index.css';

const APP_ID = '902E0DC5-A127-400A-8694-4B36B1950507'; // TODO Replace 'YOUR_APP_ID' with the actual Sendbird application ID
const sb = SendbirdChat.init({
  appId: APP_ID,
  modules: [
      new GroupChannelModule(),
  ],
}) as SendbirdGroupChat;

  
  const SendbirdChatComp = (props:any) => {
    const [userId, setUserId] = useState('');
    const [showChat, setShowChat] = useState(false); // State to toggle the visibility of the chat
    const [channelUrl, setChannelUrl] = useState(''); // State to store the channel URL
  
    useEffect(() => {
      if(props?.channel_url){
        setUserId(props.user_id);
        setChannelUrl(props.channel_url);
        setShowChat(true);
      }
    }, [props]);
  
  
    return (
      <div className="sendbird-app-container" style={{height: window.innerHeight-100}}>
        {(showChat && userId && channelUrl) && (
            <SendbirdProvider appId={APP_ID} userId={userId}>
            {showChat && <GroupChannel channelUrl={channelUrl}/>}
            </SendbirdProvider>
        ) }
      </div>
    );
  };
  
  
  
  
  export default SendbirdChatComp;
  