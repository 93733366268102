import React, { useState, useContext, useRef, useEffect } from "react";
import ImageContainer from "../ImageContainer/ImageContainer";
import { AppHelper } from "../../utils/Helper";
import { AppConfig } from "../../config";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import DataContext from "../../utils/Context/DataContext";
import QuantityButtonComponent from "../QuantityButtonComponent/QuantityButtonComponent";

const productresponsive = {
  0: { items: 1 },
};

function GroceryCardComponent(g_props: any) {
  const context: any = useContext(DataContext);
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const Spinner = () => <div className="loader-spinner mx-auto py-7"></div>;

  const get_mrp_data = (get_data: any) => format_amount(get_data);

  function format_amount(get_data: any) {
    let g_data_value = get_data?.split(".");
    if (g_data_value != undefined) {
      return (
        "₹" +
        g_data_value[0].replace(/(\d)(?=(\d\d)+\d$)/g, "$1,") +
        (g_data_value[1] != "00" ? "." + g_data_value[1] : "")
      );
    }
  }

  const getFontSize = (textLength: any, bs: any = null) => {
    const baseSize = bs != null ? bs : 21;
    if (textLength >= baseSize) {
      textLength = baseSize - 2;
    }
    if (textLength < 8) {
      if (bs != null) {
        return `12px`;
      } else {
        return `13px`;
      }
    }
    let fontSize = baseSize - textLength;
    if (g_props?.from == "category_page" && bs == null) {
      fontSize = fontSize - 2;
    }
    return `${fontSize}px`;
  };

  function get_variant_name(get_data: any) {
    let return_data: any;
    if (get_data.hasOwnProperty("variant_specifications")) {
      let spec_data = get_data.variant_specifications[0];
      let filter_data = spec_data.items.find((item) => item.checked === true);
      if (filter_data != undefined) {
        return_data = filter_data.name;
      }
    }
    return return_data;
  }
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const [isAtStart, setIsAtStart] = useState(false);
  const [isAtEnd, setIsAtEnd] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const scrollToImage = (index: number) => {
    if (scrollContainerRef.current) {
      const { clientWidth } = scrollContainerRef.current;
      scrollContainerRef.current.scrollTo({
        left: index * clientWidth,
        behavior: "smooth",
      });
      setActiveImageIndex(index);
    }
  };

  const scrollLeft = () => {
    if (activeImageIndex > 0) {
      scrollToImage(activeImageIndex - 1);
    }
  };

  const scrollRight = () => {
    if (activeImageIndex < g_props?.data.images.length - 1) {
      scrollToImage(activeImageIndex + 1);
    }
  };

  // const scrollRight = () => {
  //   if (scrollContainerRef.current) {
  //     scrollContainerRef.current.scrollBy({ left: 160, behavior: "smooth" });
  //   }
  // };

  // const scrollLeft = () => {
  //   if (activeImageIndex > 0) {
  //     scrollToImage(activeImageIndex - 1);
  //   }
  //   if (scrollContainerRef.current) {
  //     scrollContainerRef.current.scrollBy({ left: -160, behavior: "smooth" });
  //   }
  // };

  const handleDotClick = (index: number) => {
    if (scrollContainerRef.current) {
      const { clientWidth } = scrollContainerRef.current;
      scrollContainerRef.current.scrollTo({
        left: index * clientWidth,
        behavior: "smooth",
      });
      setActiveImageIndex(index); // Update the active index immediately
    }
  };

  const handleScroll = () => {
    if (scrollContainerRef.current) {
      const { scrollLeft, clientWidth } = scrollContainerRef.current;
      const newIndex = Math.round(scrollLeft / clientWidth);
      setActiveImageIndex(newIndex);
    }
  };

  const [bgColor, setBgColor] = useState('');
  
  // Simulating an API call
  const fetchDataFromAPI = async () => {
    // Simulating an API call with random data and delays
    await new Promise((resolve) => setTimeout(resolve, 20)); // Mock delay
    // On API response, pick a random color
    setBgColor(getRandomColor());
  };

  useEffect(() => {
    // Fetch data initially
    fetchDataFromAPI();

    // Set up an interval to fetch data periodically
    // const interval = setInterval(fetchDataFromAPI, 5000); // Call API every 5 seconds

    // return () => clearInterval(interval); // Cleanup on component unmount
  }, []);

  const getRandomColor = () => {
    const colors = ['#f4f2f5', '#feeaf3', '#f7f7ef', '#fbf1ef', '#feefe8', '#e7ffff', '#edfaf3'];
    return colors[Math.floor(Math.random() * colors.length)];
  };

  return (
    <>
      <div
        className={
          g_props?.fullWidth ? "col-12 p-1" : "col-6 pe-0 pb-2 box-even"
        }
        key={g_props?.index}
      >
        <div className="prod-card border-lightgray">
          <div className="">
            {g_props?.data.hasOwnProperty("images") ? (
              g_props?.view_type == "grid" &&
              g_props?.data.images.length > 1 ? (
                <div className="h-150px">
                  {/* <AliceCarousel
                    mouseTracking
                    autoPlay={false}
                    // touchMoveDefaultEvents={false}
                    // paddingRight={20}
                    // disableDotsControls
                    responsive={productresponsive}
                    disableButtonsControls
                    controlsStrategy="alternate"
                    animationDuration={800}
                  > */}
                  {/* <ImageCarousel img_data={g_props?.data} /> */}

                  {/* </AliceCarousel> */}

                  <div
                    style={{
                      position: "relative",
                      width: "100%",
                      overflow: "hidden",
                    }}
                  >
                    {/* Left Scroll Button */}
                    {!isMobile && activeImageIndex > 0 && (
                      <button
                        onClick={scrollLeft}
                        className="scroll-left fs-12px pop-font"
                        style={{
                          // position: "absolute",
                          left: "5px",
                          top: "50%",
                          // transform: "translateY(-50%)",
                          // zIndex: 1,
                          // background: "#fff",
                          // color: "rgb(0, 87, 255)",
                          // border: "none",
                          // borderRadius: "50%",
                          // padding: "2px 7px",
                          // cursor: "pointer",
                          visibility: isAtStart ? "hidden" : "visible",
                          // boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                          // fontSize:"12px",
                        }}
                      >
                        ❮
                      </button>
                    )}

                    {/* Scrollable Image Container */}
                    <div
                      ref={scrollContainerRef}
                      onScroll={handleScroll}
                      style={{
                        display: "flex",
                        overflowX: "auto",
                        scrollBehavior: "smooth",
                        gap: "6px",
                        padding: "10px",
                        scrollbarWidth: "none", // Firefox
                        msOverflowStyle: "none", // IE
                      }}
                      onClick={() => {
                        g_props?.open_quick_view();
                        //   SetQuickView({
                        //     open: true,
                        //     value: g_props?.data,
                        //   });
                      }}
                    >
                      {g_props.data.images.map((i_ele: any) => (
                        <ImageContainer
                          src={AppHelper.imageSrc(
                            context.app_data.image_base_url,
                            i_ele?.url,
                            {
                              format: "webp",
                              width: 240,
                            }
                          )}
                          thumb={AppHelper.imageSrc(
                            context.app_data.image_base_url,
                            i_ele?.url,
                            {
                              format: "webp",
                              width: 240,
                              quality: 10,
                            }
                          )}
                          class_name="Custom-prod-img"
                          // {

                          //   g_props?.data.available &&
                          //   g_props?.get_outlet_available
                          //     ? "Custom-prod-img"
                          //     : "Custom-prod-img "
                          // }
                          onIsVisible={() => {
                            console.log("ImageContainer On Is visible");
                          }}
                          loading="lazy"
                        />
                      ))}
                    </div>

                    {/* Hide scrollbar for Chrome, Edge, and Safari */}
                    <style>
                      {`
            div::-webkit-scrollbar {
              display: none;
            }
          `}
                    </style>

                    {/* Right Scroll Button */}
                    {!isMobile &&
                      !isAtEnd &&
                      g_props?.data.images.length - 1 && (
                        <button
                          onClick={scrollRight}
                          className="scroll-right fs-12px pop-font"
                          style={{
                            // position: "absolute",
                            right: "5px",
                            top: "50%",
                            // transform: "translateY(-50%)",
                            // zIndex: 1,
                            // background: "#fff",
                            // color: "rgb(0, 87, 255)",
                            // border: "none",
                            // borderRadius: "50%",
                            // padding: "2px 7px",
                            // cursor: "pointer",
                            visibility: isAtEnd ? "hidden" : "visible",
                            // boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                            // fontSize:"12px",
                          }}
                        >
                          ❯
                        </button>
                      )}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      position: "relative",
                      bottom: "5px",
                      right: "4px",
                      gap: "2px",
                    }}
                  >
                    {g_props.data.images.map((_, index: any) => (
                      <div
                        key={index}
                        onClick={() => scrollToImage(index)}
                        style={{
                          width: "5px",
                          height: "5px",
                          borderRadius: "50%",
                          backgroundColor:
                            activeImageIndex === index
                              ? "rgb(0, 87, 255)"
                              : "#ccc",
                          transition: "background-color 0.3s",
                        }}
                      ></div>
                    ))}
                  </div>
                </div>
              ) : (
                <div
                  className="h-140px p-2"
                  onClick={() => {
                    g_props?.open_quick_view();
                    //   SetQuickView({
                    //     open: true,
                    //     value: g_props?.data,
                    //   });
                  }}
                >
                  <ImageContainer
                    src={AppHelper.imageSrc(
                      context.app_data.image_base_url,
                      g_props?.data.images[0]?.url,
                      {
                        format: "webp",
                        width: 320,
                      }
                    )}
                    thumb={AppHelper.imageSrc(
                      context.app_data.image_base_url,
                      g_props?.data.images[0]?.url,
                      {
                        format: "webp",
                        width: 320,
                        quality: 10,
                      }
                    )}
                    class_name={
                      g_props?.data.available && g_props?.get_outlet_available
                        ? "w-100 prod-image "
                        : "w-100 prod-image-gray "
                    }
                    style={{backgroundColor: bgColor}}
                    onIsVisible={() => {
                      console.log("ImageContainer On Is visible");
                    }}
                  />
                </div>
              )
            ) : (
              <div className="h-140px prod-no-image-gray"
              onClick={() => {
                g_props?.open_quick_view();
                //   SetQuickView({
                //     open: true,
                //     value: g_props?.data,
                //   });
              }}
              style={{backgroundColor: bgColor}}></div>
            )}
          </div>
          <div className="px-2 pt-2 mb-2">
            <p className="text-dark pop-font double">{g_props?.data.name}</p>
            {context.app_data.selected_section.order_type == "Vendors" && context.app_data.selected_section.sector == 1 && (
              <p className="text-gray v-small pop-font">
                {g_props.data.type == "group" &&
                  get_variant_name(g_props?.data)}
                {g_props.data.hasOwnProperty("basic_specifications") &&
                Object.keys(g_props.data.basic_specifications).length > 0
                  ? "," + g_props.data.basic_specifications.weight +
                    g_props.data.basic_specifications.weight_uint
                  : null}
              </p>
            )}

          </div>
          <div className="px-2 pb-2 mt-auto">
            <div className="row">
              <div className="col-5 p-0 w-45">
                {g_props?.data.hasOwnProperty("show_price_without_gst") ? (
                  g_props?.data.show_price_without_gst ? (
                    <>
                      {g_props?.data.price_without_gst != "" &&
                      g_props?.data.price_without_gst != null ? (
                        <p
                          className="text-dark"
                          style={{
                            fontSize: getFontSize(
                              get_mrp_data(g_props?.data?.price_without_gst)
                                ?.length
                            ),
                          }}
                        >
                          {/* ₹{g_props?.data.price_without_gst} */}
                          {get_mrp_data(g_props?.data?.price_without_gst)}
                        </p>
                      ) : null}
                    </>
                  ) : (
                    <>
                      {g_props?.data.price_with_gst != "" &&
                      g_props?.data.price_with_gst != null ? (
                        <p
                          className="text-dark"
                          style={{
                            fontSize: getFontSize(
                              get_mrp_data(g_props?.data?.price_with_gst)
                                ?.length
                            ),
                          }}
                        >
                          {/* ₹{g_props?.data.price_with_gst} */}
                          {get_mrp_data(g_props?.data?.price_with_gst)}
                        </p>
                      ) : null}
                    </>
                  )
                ) : null}
                <p
                  className="strike"
                  style={{
                    fontSize: getFontSize(
                      get_mrp_data(g_props?.data?.mrp)?.length,
                      18
                    ),
                  }}
                >
                  {get_mrp_data(g_props?.data?.mrp)}
                </p>
                {/* <p className="strike">₹ {g_props?.data.mrp}</p> */}
              </div>
              <div className="col-7 px-0 w-55">
                {g_props?.from == "vendor_page" ||
                g_props?.from == "category_page" ? (
                  g_props?.get_outlet_available ? (
                    g_props?.data.available ? (
                      g_props?.data.addbtn ? (
                        <div>
                          <div className="prod-add-btn cursor">
                            <QuantityButtonComponent
                              from_page={"vendor"}
                              section_slug={
                                context.app_data.selected_section.slug
                              }
                              context_data={context}
                              minus={() => {
                                g_props?.onminus();
                              }}
                              pluse={() => {
                                g_props?.onpluse();
                              }}
                              addcount={g_props?.data.addcount}
                              api_loading={g_props?.data.api_loading}
                            />
                            {/* <div className="d-flex">
                              {g_props?.data.api_loading ? (
                                <Spinner />
                              ) : (
                                <>
                                  //-
                                  <div
                                    className="cursor pr-1 center"
                                    onClick={() => {
                                      g_props?.onminus();
                                    }}
                                  >
                                    <img
                                      src={
                                        AppConfig.CDN_Media_url +
                                        "b48a5c15d42fc79330a9658ff3779170.svg"
                                      }
                                      className="type-img"
                                    />
                                  </div>
                                  //qty
                                  <div className="w-100 center">
                                    <p className="smooth">
                                      <b>{g_props?.data.addcount}</b>
                                    </p>
                                  </div>
                                  //+
                                  <div
                                    className="cursor pl-1"
                                    onClick={() => {
                                      g_props?.onpluse();
                                    }}
                                  >
                                    <img
                                      src={
                                        AppConfig.CDN_Media_url +
                                        "2a39073bf6a8f72e9d703ad4b9cdeab4.svg"
                                      }
                                      className="type-img"
                                    />
                                  </div>
                                </>
                              )}
                            </div> */}
                            {g_props?.data.customize ? (
                              <fieldset className="center-text-in-border">
                                <legend>More Options</legend>
                              </fieldset>
                            ) : null}
                          </div>
                        </div>
                      ) : (
                        <div>
                            {g_props?.data.api_loading ? (
                              <div className="prod-add-btn cursor">
                                <p className="">
                                  <Spinner />
                                </p>
                                {g_props?.data.customize ? (
                                  <fieldset className="center-text-in-border">
                                    <legend>More Options</legend>
                                  </fieldset>
                                ) : null}
                              </div>
                            ) : (
                              <div className="prod-add-btn cursor" onClick={(event) => {
                                console.log("variant");
                                g_props?.onadd();
                              }}>
                                <p className="">
                                  ADD
                                </p>
                                {g_props?.data.customize ? (
                                  <fieldset className="center-text-in-border">
                                    <legend>More Options</legend>
                                  </fieldset>
                                ) : null}
                              </div>
                            )}
                        </div>
                      )
                    ) : (
                      <>
                        <div className="prod-add-btn">
                          {g_props?.data.next_available_at != "" &&
                          g_props?.data.next_available_at != null ? (
                            <p className="">
                              Next Available at{" "}
                              {g_props?.data.next_available_at}
                            </p>
                          ) : (
                            <p className="fs-8px py-1">Unavailable</p>
                          )}
                        </div>
                      </>
                    )
                  ) : null
                ) : null}

                {g_props?.from == "search_page" ? (
                  g_props?.data.available ? (
                    <div>
                      <div
                        className="prod-add-btn cursor"
                        onClick={(event) => {
                          console.log("variant");
                          // const navigate = useNavigate();
                          g_props?.navigate();
                        }}
                      >
                        {g_props?.data.api_loading ? (
                          <p className="">
                            <Spinner />
                          </p>
                        ) : (
                          <p className="">ADD</p>
                        )}
                        {g_props?.data.customize ? (
                          <fieldset className="center-text-in-border">
                            <legend>More Options</legend>
                          </fieldset>
                        ) : null}
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="prod-add-btn">
                        {g_props?.data.next_available_at != "" &&
                        g_props?.data.next_available_at != null ? (
                          <p className="">
                            Next Available at {g_props?.data.next_available_at}
                          </p>
                        ) : (
                          <p className="fs-8px py-1">Unavailable</p>
                        )}
                      </div>
                    </>
                  )
                ) : null}

                {/* <div className="prod-add-btn">
                ADD
                {g_props?.data.variable == true ? (
                  <fieldset className="center-text-in-border">
                    {" "}
                    <legend>{g_props?.data.no_of_variable} Options</legend>
                  </fieldset>
                ) : null}
              </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default GroceryCardComponent;
