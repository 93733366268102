import { useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

export const Header = () => {
  return (
    <div className="bg-fff p-2 pb-0">
      <div>
        <p>
          <Skeleton width="25%" height="8px" />
        </p>
        <p>
          <Skeleton width="45%" height="8px" />
        </p>
      </div>
    </div>
  )
}

export const Search = () => {
  return (
    <div className="bg-fff px-2 pb-2 sticky-sm-60px sticky-md-60px shadow-bottom">
      <Skeleton width="100%" height="30px" />
    </div>
  )
}

export const FoodsForYou = () => {
  return (
    <div className="shadow-bottom food-customer-profile-div  mt-2">
      <div className="py-3 card shadow-bottom border-top-radius">
        <div>
          <div className="d-flex ps-2 align-items-center pb-1">
            <Skeleton className="head-img" circle={true} />
            <div className="ps-1">
              <Skeleton width={100} height="10px" />
            </div>
          </div>
          <p className="ps-3">
            <Skeleton width={250} height="10px" />
          </p>
        </div>
        <div className="mt-2">
          <div className="restaurant-list d-flex overflowX-hidden">
            {[1, 2, 3, 4, 5].map((data, index) => (
              <div className="restaurant-list__item mt-3 ps-1 pr-1" key={index}>
                <div className="restaurant-list__item_card">
                  <Skeleton height={150} width={200} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export const TopBrands = () => {
  return (
    <div className="foodbrand mt-2 py-3 card shadow-bottom">
      <div>
        <div className="d-flex ps-2 align-items-center pb-1">
          <Skeleton className="head-img" circle={true} />
          <div className="ps-1">
            <Skeleton width={100} height="10px" />
          </div>
        </div>
        <p className="ps-3">
          <Skeleton width={250} height="10px" />
        </p>
      </div>
      <div className="d-flex  overflowX-hidden">
        {[1, 2, 3, 4, 5].map((data, index) => (
          <div className="mt-3 p-3" key={index}>
            <div>
              <div className="center w-100">
                <div className=" ">
                  <Skeleton circle={true} height={70} width={70} />
                </div>
              </div>
              <div className="text-center  mt-1 ">
                <Skeleton height={10} width={20} />
                <div className="center">
                  <Skeleton height={8} width={40} />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export const PopularFoodVarieties = () => {
  const [width, SetWidth] = useState(innerWidth);
  return (
    <div className="mt-2 py-3 card shadow-bottom">
      <div>
        <div className="d-flex ps-2 align-items-center pb-1">
          {/* <Skeleton className="head-img" circle={true} /> */}
          <div className="">
            <Skeleton width={100} height="10px" />
          </div>
        </div>
        <p className="ps-2">
          <Skeleton width={250} height="10px" />
        </p>
      </div>

      <div className="continer">
        <div className="row  overflowX-hidden ">
          {width < 786 ? [1, 2, 3, 4].map((category, cat_index) => (
            <div  className="col-lg-2 col-md-3 col-3 p-2" key={cat_index}>
              <div className="text-center">
                <Skeleton height={70} width={70} circle={true} />
                <p className="pt-1">
                  <Skeleton height={8} width={50} />
                </p>
              </div>
            </div>
          )) : [1, 2, 3, 4, 5, 6].map((category, cat_index) => (
            <div  className="col-lg-2 col-md-3 col-3 p-2" key={cat_index}>
              <div className="text-center">
                <Skeleton height={70} width={70} circle={true} />
                <p className="pt-1">
                  <Skeleton height={8} width={50} />
                </p>
              </div>
            </div>
           )) }
        </div>
        <div className="d-flex overflowX-hidden">
        {width < 786 ? [1, 2, 3, 4].map((category, cat_index) => (
            <div  className="col-lg-2 col-md-3 col-3 p-2" key={cat_index}>
              <div className="text-center">
                <Skeleton height={70} width={70} circle={true} />
                <p className="pt-1">
                  <Skeleton height={8} width={50} />
                </p>
              </div>
            </div>
          )) : [1, 2, 3, 4, 5, 6].map((category, cat_index) => (
            <div  className="col-lg-2 col-md-3 col-3 p-2" key={cat_index}>
              <div className="text-center">
                <Skeleton height={70} width={70} circle={true} />
                <p className="pt-1">
                  <Skeleton height={8} width={50} />
                </p>
              </div>
            </div>
           )) }
        </div>
      </div>
    </div>
  )
}

export const TodayMenu = () => {
  return (
    <div className="mt-2 py-3 card shadow-bottom">
      {/* <div>
        <div className="d-flex ps-2 align-items-center pb-1">
          <Skeleton className="head-img" circle={true} />
          <div className="ps-1">
            <Skeleton width={100} height="10px" />
          </div>
        </div>
        <p className="ps-3">
          <Skeleton width={250} height="10px" />
        </p>
      </div> */}

      <div className="today d-flex overflowX-hidden">
        {[1, 2, 3, 4, 5].map((banner, index) => (
          <div className="p-2">
            <Skeleton width={280} height={150} />
          </div>
        ))}
      </div>
    </div>
  )
}

export const RecommendedBrands = () => {
  return (
    <div className="mt-2 py-3 card shadow-bottom">
      <div>
        <div className="row p-0">
          <div className="col-8 pe-0">
            <div className="d-flex align-items-center pb-1">
              <Skeleton className="head-img" circle={true} />
              <div className="ps-1">
                <Skeleton width={100} height="10px" />
              </div>
            </div>
            <p className="ps-1">
              <Skeleton width={180} height="10px" />
            </p>
          </div>
          <div className="col-4 text-right">
            <div className="flex justify-end cursor">
              <p className="ps-3">
                <Skeleton width={50} height="10px" />
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className=" p-2">
        <div className=" row">
          {[1, 2, 3, 4].map((data, index) => (
            <div
              className="col-6 col-md-4 col-lg-3 restaurant-list__item mt-3 ps-1 pr-1 cursor"
              key={index}
            >
              <div className="restaurant-list__item_card">
                <div className="container">
                  <Skeleton height={160} />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export const Offers = () => {
  return (
    <div className="mt-2 py-3 card shadow-bottom">
      <div className="row p-0">
        <div className="col-8 pe-0">
          <div className="d-flex align-items-center pb-1">
            <Skeleton className="head-img" circle={true} />
            <div className="ps-1">
              <Skeleton width={100} height="10px" />
            </div>
          </div>
          <p className="ps-1">
            <Skeleton width={180} height="10px" />
          </p>
        </div>
        <div className="col-4 text-right">
          <div className="flex justify-end cursor">
            <p className="ps-3">
              <Skeleton width={50} height="10px" />
            </p>
          </div>
        </div>
      </div>

      <div className="mt-2">
        <div className="restaurant-list row p-2">
          {[1, 2, 3, 4].map((data, index) => (
            <div
              className="col-6 col-md-4 col-lg-3 restaurant-list__item mt-3 ps-1 pr-1 cursor"
              key={index}
            >
              <div className="restaurant-list__item_card">
                <div className="container">
                  <Skeleton height={160} />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export const AllRestaurants = () => {
  return (
    <div className="mt-2 py-3 card shadow-bottom">
      <div>
        <div className="d-flex align-items-center ps-2">
          {/* <Skeleton className="head-img" circle={true} /> */}
          <div className="">
            <Skeleton width={100} height="10px" />
          </div>
        </div>
        <p className="ps-2">
          <Skeleton width={180} height="10px" />
        </p>
      </div>

      <div className="mt-3">
        <div className="row overflowX-hidden">
          {[1, 2, 3, 4, 5, 6].map((data, index) => (
            <div
              className="col-12 col-md-6 col-lg-4 cursor restaurant-list__item"
              key={index}
            >
              <div>
                <div className="row pt-3">
                  <div className="col-5 p-0">
                    <div className="container p-0">
                      <Skeleton height={160} />
                    </div>
                  </div>
                  <div className="col-7 pr-0">
                    <Skeleton width="75%" height={10} />
                    <Skeleton width="100%" height={10} count={2} />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default function SkeletonDashboard() {
  return (
    <div className="page-main food-page mt-2 mb-5 px-sm-0 px-md-2">
      {/* Header */}
      {/* <Header /> */}
      {/* Search */}
      {/* <Search /> */}
      {/* Foods For You */}
      {/* <FoodsForYou /> */}
      {/* Top Brands */}
      {/* <TopBrands /> */}
      {/* Today's menu */}
      {/* <TodayMenu /> */}
      {/* Popular Food Varieties */}
      {/* <PopularFoodVarieties /> */}
      {/* Recommended Brands */}
      {/* <RecommendedBrands /> */}
      {/* Offers */}
      {/* <Offers /> */}
      {/* All Restaurants */}
      <AllRestaurants />
    </div>
  )
}
