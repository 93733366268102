import React , {useContext} from "react";
import { Helmet } from "react-helmet";
import DataContext from "../../utils/Context/DataContext";

export default function MetaPixel() {

  const context: any = useContext(DataContext);
  let add_user_data = {
    fn : context.app_data.user_details.name,
    external_id : context.app_data.user_details.id
  }
  return (
    <Helmet>
      {/* <script id="facebook-pixel-script">
      {
        `YOUR_FUNCTION`
      }</script>
      <noscript id="facebook-pixel-image">
      {
        `YOUR_IMAGE`
      }</noscript> */}

      {/* <script
        dangerouslySetInnerHTML={{
          __html: `!(function (f, b, e, v, n, t, s) {
            if (f.fbq) return;
            n = f.fbq = function () {
                n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
            };
            if (!f._fbq) f._fbq = n;
            n.push = n;
            n.loaded = !0;
            n.version = "2.0";
            n.queue = [];
            t = b.createElement(e);
            t.async = !0;
            t.src = v;
            s = b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t, s);
            })(
            window,
            document,
            "script",
            "https://connect.facebook.net/en_US/fbevents.js"
            );
            fbq("init", "892557469108794");
            fbq("track", "PageView");`,
        }}
      />

      <noscript
        dangerouslySetInnerHTML={{
          __html: `<img
          height="1"
          width="1"
          style="display:none"
          src="https://www.facebook.com/tr?id=892557469108794&ev=PageView&noscript=1"
        /> `,
        }}
      /> */}

      <script>
        {`!(function (f, b, e, v, n, t, s) {
            if (f.fbq) return;
            n = f.fbq = function () {
                n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
            };
            if (!f._fbq) f._fbq = n;
            n.push = n;
            n.loaded = !0;
            n.version = "2.0";
            n.queue = [];
            t = b.createElement(e);
            t.async = !0;
            t.src = v;
            s = b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t, s);
            })(
            window,
            document,
            "script",
            "https://connect.facebook.net/en_US/fbevents.js"
            );
            fbq("init", "892557469108794",add_user_data);
            fbq("track", "PageView");`}
      </script>
      <noscript>
        {`<img
          height="1"
          width="1"
          style="display:none"
          src="https://www.facebook.com/tr?id=892557469108794&ev=PageView&noscript=1"
        />`}
      </noscript>
    </Helmet>
  );
}
